import { Box, SxProps, Theme, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import Skeleton from "@mui/material/Skeleton";

import { CloseButton } from "./Buttons";
import { useCurrentUser } from "../hooks/useCurrentUser";

interface TitleProps {
  className?: string;
  sx?: SxProps<Theme>;
}

export const Title: React.FC<React.PropsWithChildren<TitleProps>> = (props) => {
  const { className, sx = [] } = props;
  return (
    <Typography variant="h2" component="h1" className={className} sx={sx}>
      {props.children ? (
        props.children
      ) : (
        <Skeleton
          animation="wave"
          variant="text"
          sx={{ minWidth: "160px", maxWidth: "400px", mt: 1.5 }}
          data-testid="title-loading"
        />
      )}
    </Typography>
  );
};

interface HeaderActionsProps {
  actions?: JSX.Element[];
  closeUrl?: string;
}

const HeaderActions: React.FC<HeaderActionsProps> = (props) => {
  if (props.actions) {
    return (
      <Box component="span"  paddingTop={"22px"} flexShrink={0}>
        <CloseButton closeUrl={props.closeUrl} />
        {props.actions.map((action, index) => action)}
      </Box>
    );
  }
  return <></>;
};

export interface HeaderProps {
  actions?: JSX.Element[];
  children?: React.ReactNode;
  closeUrl?: string;
}

const Header: React.FC<HeaderProps> = (props) => {
  return (
    <Box
      component="header"
      pl={{ xs: 2, sm: 6 }}
      pr={{ xs: 2, sm: 6 }}
      sx={{ backgroundColor: grey[200] }}
      display="flex"
    >
      <Box pt={{ xs: 1, sm: 4 }} pb={{ xs: 1, sm: 2 }} flexGrow={1}>
        <Title>{props.children}</Title>
      </Box>
      <HeaderActions actions={props.actions} closeUrl={props.closeUrl} />
    </Box>
  );
};

export const ListingHeader: React.FC<HeaderProps> = (props) => {
  const currentUser = useCurrentUser();
  return (
    <Box
      component="header"
      pl={{ xs: 2, sm: 6 }}
      pr={{ xs: 2, sm: 6 }}
      sx={{ backgroundColor: grey[200] }}
      display="flex"
    >
      <Box pt={{ xs: 1, sm: 4 }} pb={{ xs: 1, sm: 2 }} flexGrow={1}>
        <Title>{props.children}</Title>
      </Box>
      <Box pt={{ xs: 1, sm: 4 }} pb={{ xs: 1, sm: 2 }} >
      <Typography  sx={{
        fontSize:"14px",
        fontWeight:"500",
        color:"black"
      }}>
      {currentUser ? (
        <>{currentUser.name} - <span style={{fontWeight:"300"}}>{currentUser?.company?.name}</span>&nbsp;&nbsp;&nbsp; |&nbsp;&nbsp;&nbsp; V 4.10.3
        </>
      ) : (
        <Skeleton
          animation="wave"
          variant="text"
          sx={{ minWidth: "160px", maxWidth: "400px", mt: 1.5 }}
          data-testid="title-loading"
        />
      )}
    </Typography>
      </Box>
  
    </Box>
  );
};

export const BreadcrumbHeader = (props) => {
  return (
    <Box
      component="header"
      paddingLeft={{ xs: 2, sm: 6 }}
      paddingRight={{ xs: 2, sm: 6 }}
      bgcolor={grey[200]}
      display="flex"
    >
      <Box
        flexGrow={1}
        paddingTop={{ xs: 2, sm: 3 }}
        paddingBottom={{ sm: 1.5 }}
      >
        {props.children}
      </Box>
      <HeaderActions actions={props.actions} />
    </Box>
  );
};

export const BreadcrumbHeaderListing = (props) => {
  const currentUser = useCurrentUser();
  return (
    <Box
      component="header"
      paddingLeft={{ xs: 2, sm: 6 }}
      paddingRight={{ xs: 2, sm: 6 }}
      bgcolor={grey[200]}
      display="flex"
    >
      <Box
        flexGrow={1}
        paddingTop={{ xs: 2, sm: 3 }}
        paddingBottom={{ sm: 1.5 }}
      >
        {props.children}
      </Box>
      <Box pt={{ xs: 1, sm: 4 }} pb={{ xs: 1, sm: 2 }} >
          <Typography  sx={{
        fontSize:"14px",
        fontWeight:"500",
        color:"black"
      }}>
      {currentUser ? (
         <>{currentUser.name} - <span style={{fontWeight:"300"}}>{currentUser?.company?.name}</span>&nbsp;&nbsp;&nbsp; |&nbsp;&nbsp;&nbsp; V 4.10.3
        </>
      ) : (
        <Skeleton
          animation="wave"
          variant="text"
          sx={{ minWidth: "160px", maxWidth: "400px", mt: 1.5 }}
          data-testid="title-loading"
        />
      )}
      </Typography>
        </Box>
    </Box>
  );
};

export default Header;
