import {  useMemo, useRef, useState } from "react";

import {  Skeleton } from "@mui/material";
import { Field, Form, Formik, FormikValues } from "formik";
import { TextField } from "formik-mui";
import { useSnackbar } from "notistack";
import * as Yup from "yup";

import { axios } from "../api";
import { ActionButton, CancelButton } from "../components/Buttons";
import LoadingSpinner from "../components/Loading";
import Sheet, {
  SheetActions,
  SheetContent,
  SheetHeader,
} from "../components/Sheet";
import { useGlobalConfig } from "../hooks/useGlobalConfig";
import { ItemPriceAutocomplete } from "../parts/ItemPriceAutocomplete";
import type { InvoiceDetails} from "../invoices/types";
import type { IPartDetail,  Part, PriceOption } from "../parts/types";
import { PartAutocomplete } from "./PartAutocomplete";
import { ItemAutocomplete } from "./FormikItemAutocomplete";
import { ItemList } from "../inventory/types";
import CustomSnackbarContent from "../components/CustomSnackbarContent";
import { handleAPIErrors } from "../utils/FormikErrors";


export interface InvoicePartProps {
//   item: ItemList;
  job_id?: number;
  onClose: () => void;
  open: boolean;
  originalUrl?: string;
  // quantity?: number;
  value?: number | string;
  invoiceDetail?:InvoiceDetails;
}

const InvoicePart: React.FC<InvoicePartProps> = (props) => {
  const { onClose, open,   value, invoiceDetail } = props;


  const formRef = useRef<FormikValues>();



  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  return (
    <Sheet open={open} onClose={onClose}>
      <SheetHeader onClose={onClose} title="Invoice Part">
      </SheetHeader>
      <SheetContent>
          <InvoicePartForm
            value={value}
            formRef={formRef}
            onClose={onClose}
            invoiceDetail={invoiceDetail}
          />
      </SheetContent>
      <SheetActions>
        <CancelButton onClick={onClose} />
        <ActionButton children={"Submit"} onClick={handleSubmit} />
      </SheetActions>
    </Sheet>
  );
};

interface InvoicePartFormProps {
  formRef: any;
  onClose: () => void;
  part?: IPartDetail;
  value?: number | string;
  invoiceDetail?:InvoiceDetails;
}

export const InvoicePartForm = (props: InvoicePartFormProps) => {
  const {
    formRef,
    onClose,
    part,
    invoiceDetail
  } = props;

  const globalConfig = useGlobalConfig();
  const { enqueueSnackbar } = useSnackbar();
  const [prices, setPrices] = useState<PriceOption[]>([]);

  const InvoicePartSchema = useMemo(() => {
    let schema = Yup.object().shape({
      quantity: Yup.number().positive().required("Required"),
      value: Yup.object().required("Required"),
    });
    return schema;
  }, []);

  const initialValues = useMemo(
    () => ({
      part: part,
      invoice: invoiceDetail,
      item: "" as unknown as ItemList,
      part_obj: "" as unknown as Part,
      quantity: null,
      value: "" as unknown as PriceOption,
      item_id:0,
      uom:""
    }),
    [part,invoiceDetail]
  );


  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      innerRef={formRef}
      validationSchema={InvoicePartSchema}
      onSubmit={(values, { setSubmitting, setErrors }) => {
        let method = "post";
        let url = "/v6/invoice";
        let msgError: string;

        // separate all the objects
        const { part,invoice, ...line } =
          values;

        // the remaining fields are the line
        const data = { lines: [line] };

        // line.value is an object too, we only need the value field
        // @ts-ignore
        data["lines"][0].value = data.lines[0].value.value;

        if (invoice) {
          // change method to patch to update the invoice
          method = "patch";
          url = url + "/" + invoice.id;
          msgError = "updating";
        } else {
          msgError = "creating";
          // data["client_id"] = customer.id;
          // data["purchase_order"] = purchase_order;
          // if (division) {
          //   // @ts-ignore
          //   data["division_id"] = division.id;
          // }
        }

        axios({
          method: method,
          url: url,
          data: data,
        })
          .then((resp) => {
            setSubmitting(false);

            enqueueSnackbar(
              <CustomSnackbarContent
                message={`Part added in invoice `}
                // uri={invoiceUri.href}
                external={true}
              />,
              {
                variant: "success",
              }
            );
            onClose();
          })
          .catch((error) => {
            handleAPIErrors(error, setErrors);
            setSubmitting(false);
            enqueueSnackbar(`Error ${msgError} invoice`, {
              variant: "error",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
            });
          });
      }}
    >
      {({
        isSubmitting,
        values,
        setFieldValue,
        handleBlur,
        handleChange,
        errors,
      }) => (
        <Form>
          <Field name="item_id" type="hidden" />
          <Field
            component={PartAutocomplete}
            id="part"
            name="part"
            onChange = {(e,value)=>{
             if (value)
            {
              setFieldValue("part_obj",value)
              setPrices([
                {
                  name: `List Price ${Number(value.list_price).toFixed(
                    globalConfig?.value_rounding ?? 2
                  )} ${value.list_price_currency}`,
                  value: Number(value.list_price)
                    .toFixed(globalConfig?.value_rounding ?? 2)
                    .toString(),
                },
                {
                  name: `Item Value ${Number(value?.sell || 0).toFixed(
                    globalConfig?.value_rounding ?? 2
                  )} ${value?.sell_currency || "USD"}`,
                  value: Number(value.sell)
                    .toFixed(globalConfig?.value_rounding ?? 2)
                    .toString(),
                },
                { name: "Zero Value 0.00", value: "0.00" },
              ]);
              setFieldValue("uom", value?.uom);
             }
             else{
              setPrices([]);
              setFieldValue("part_obj",null)
              setFieldValue("uom", "");
              setFieldValue("item_id",0)
              setFieldValue("item",null)
             }
            
            }}
          />
         {/* {
          values?.part_obj?.id? <Field
          component={ItemAutocomplete}
          id="item"
          name="item"
          pardId = {values?.part_obj?.id || 0}
        />:            
        <Skeleton animation="wave" sx={{ fontSize: "2rem" }} />
         } */}
          <Field
          component={ItemAutocomplete}
          id="item"
          name="item"
          pardId = {values?.part_obj?.id || 0}
          onChange = {(event,value)=>{
            setPrices([
              prices[0],{
                name: `Item Value ${Number(value?.sell || 0).toFixed(
                  globalConfig?.value_rounding ?? 2
                )} ${value?.sell_currency || "USD"}`,
                value: Number(value.sell)
                  .toFixed(globalConfig?.value_rounding ?? 2)
                  .toString(),
              },prices[2]
            ])
          }}
        />
          {globalConfig ? (
            <div style={{ display: "flex", width: "100%" }}>
                <Field
                  required
                  fullWidth
                  component={TextField}
                  name="quantity"
                  label="Quantity"
                  inputProps={{ inputMode: "decimal", min: 0 }}
                  onBlur={(event) => {
                    const value = Number(values.quantity);
                    const formatted = value.toFixed(globalConfig.quantity_rounding);
                    setFieldValue("quantity", Number(formatted));
                    handleBlur(event);
                  }}
                  sx={{ flex: 1, marginRight: "1rem" }}
                />

                {/* UOM Field */}
                <div style={{ flex: 1 }}>
                <Field
                  component={TextField}
                  name="uom"
                  id="uom"
                  label="UOM"
                  sx={{ width: "100%" }}
                  
                  formControl={{
                    fullWidth: true,
                  }}
                  InputProps={{
                    disabled: true,
                  }}
                >
                </Field>
                </div>
              </div>
          ) : (
            <Skeleton animation="wave" sx={{ fontSize: "2rem" }} />
          )}
           <Field
            required
            id="value"
            name="value"
            freeSolo
            component={ItemPriceAutocomplete}
            label="Value"
            prices={prices}
            disabled={prices.length === 0}
          />
          {isSubmitting && <LoadingSpinner />}
        </Form>
      )}
    </Formik>
  );
};

export default InvoicePart;
