import {   useRef } from "react";

import { Grid, MenuItem, Skeleton } from "@mui/material";
import { Select, TextField } from "formik-mui";
import { Field, Form, Formik, FormikValues } from "formik";
import { useSnackbar } from "notistack";

import { axios } from "../api";
import Sheet, {
    SheetActions,
    SheetContent,
    SheetHeader,
    SheetHeaderItem,
} from "../components/Sheet";
import LoadingSpinner from "../components/Loading";
import { ActionButton, CancelButton } from "../components/Buttons";

import { useConfig } from "../hooks/useConfig";

import { InvoiceLines } from "./types";
import { AxiosResponse } from "axios";
import { useGlobalRounding } from "../hooks/useGlobalRounding";


export interface InvoiceEditPartProps {
    open: boolean;
    onClose: () => void;
    invoicePart:InvoiceLines;
}



const InvoiceEditPart: React.FC<InvoiceEditPartProps> = (props) => {
    const {
        open,
        onClose,
        invoicePart,
    } = props;
    const formRef = useRef<FormikValues>();
    const invoiceConfig = useConfig("invoices")
    const handleAddDemand = () => {
        if (formRef.current) {
            formRef.current.handleSubmit();
        }
    };
    

    return (
        <Sheet open={open} onClose={onClose}>
            <SheetHeader onClose={onClose} title="Edit Part">
                <SheetHeaderItem label="P/N" content={invoicePart?.part_number || ""}></SheetHeaderItem>
                <SheetHeaderItem
                    label="Description"
                    content={invoicePart?.line_description || ""}
                ></SheetHeaderItem>
            </SheetHeader>
            <SheetContent>
              {
                invoicePart && invoiceConfig ?(
                    <InvoiceEditPartForm  formRef = {formRef} invoiceConfig={invoiceConfig} invoicePart={invoicePart} onClose = {onClose}/>
                ):(
                   <LoadingSpinner/>
                )
              }
            </SheetContent>
            <SheetActions>
                <CancelButton onClick={onClose} />
                <ActionButton children={"Add Demand"} onClick={handleAddDemand} />
            </SheetActions>
        </Sheet>
    );
};

interface InvoiceEditPartFormProps {
    invoicePart:InvoiceLines;
    invoiceConfig:any;
    formRef:any;
    onClose:()=>void;
}

const InvoiceEditPartForm: React.FC<InvoiceEditPartFormProps> = (props) => {
    const { invoicePart, invoiceConfig , formRef,onClose} = props
    const {enqueueSnackbar} = useSnackbar()
    const globalRounding = useGlobalRounding();

    const initialValues = {
        quantity: invoicePart.quantity,
        sell: globalRounding(invoicePart.sell),
        buy: globalRounding(invoicePart.buy),
        price: globalRounding(invoicePart.price),
        uom:invoicePart.uom,
        department:invoicePart.type_id,
        discount:invoicePart.discount,
        serial_number:invoicePart.serail_number,
        batch:invoicePart.batch,
        note:invoicePart.note
    }

    return (
        <>
        <Formik
         innerRef={formRef}
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting, setErrors, resetForm  })=>{
            console.log(values,"values")
            const invoiceLine  = Object.entries(values).reduce((acc,[key,value])=>{
                acc[key] = value

                return acc
            },{} as InvoiceLines)
            console.log(invoiceLine,"invoice line")
            axios({
                method: "put",
                url: `/v6/invoice/line/${invoicePart.id}`,
                data: invoiceLine,
            }).then((resp:AxiosResponse<InvoiceLines>)=>{
                enqueueSnackbar("Successfully updated",{
                    variant:"success"
                })
                onClose()
            }).catch((error)=>{
                console.log(error,"error")
                enqueueSnackbar("Error updating invoice item",{
                    variant:"error"
                })
            })
        }}
        >
            <Form>
            {/* <Grid container spacing= {1}> */}
                <Grid item xs = {12} md ={6} lg={6}>
                    <Field
                    fullWidth
                    component = {TextField}
                    name = "quantity"
                    label = "Quantity"
                    InputProps={{
                        disabled: true,
                      }}
                    />
                    <Field
                    fullWidth
                    component = {TextField}
                    name = "uom"
                    label = "UOM"
                    InputProps={{
                        disabled: true,
                      }}
                    >
                     
                    </Field>
                    <Field
                    fullWidth
                    name = "price"
                    component = {TextField}
                    label = "Cost (Per UOM)"
                    InputProps={{
                        disabled: true,
                      }}
                    
                    />
                    <Field
                    fullWidth
                    name = "buy"
                    label = "Unit Price (Per UOM)"
                    component = {TextField}
                    />
                    <Field
                    fullWidth
                    name = "discount"
                    label = "Discount"
                    component = {TextField}
                    />
                    <Field
                    fullWidth
                    name = "sell"
                    label = "Sell Price (Per UOM)"
                    component = {TextField}
                    /> 
                    <Field
                    fullWidth
                    name = "serial_number"
                    label = "Serial No."
                    component = {TextField}
                    InputProps={{
                        disabled: true,
                      }}
                    />
                     <Field
                    fullWidth
                    name = "batch"
                    label = "Batch #"
                    component = {TextField}
                    InputProps={{
                        disabled: true,
                      }}
                    />
                    {
                        invoiceConfig?                    
                        (
                            <Field
                                required
                                component={Select}
                                id="department"
                                name="department"
                                label="Department *"
                            >
                                {Object.entries(invoiceConfig.line_item_types).map(
                                    ([key, value]) => (
                                        <MenuItem value={key} key={key}>
                                            {value as unknown as string}
                                        </MenuItem>
                                    )
                                )}
                            </Field>
                        ) : (
                            <Skeleton animation="wave" sx={{ fontSize: "2rem" }} />
                        )
                    }
                    <Field
                    component = {TextField}
                    fullWidth
                    multiline
                    rows="4"
                    name = "note"
                    label = "Notes"
                    />
                </Grid>
            {/* </Grid> */}
            </Form>
        </Formik>
        </>
    )
}


export default InvoiceEditPart;
