import { useEffect, useMemo, useState } from "react";
import { Grid, TextField as MUITextField, Typography } from "@mui/material";
import MuiAutocomplete from "@mui/material/Autocomplete";
// import match from "autosuggest-highlight/match";
// import parse from "autosuggest-highlight/parse";
import { AxiosResponse } from "axios";
import {
  AutocompleteProps,
  AutocompleteRenderInputParams,
  fieldToAutocomplete,
  fieldToTextField,
} from "formik-mui";
import { throttle } from "lodash";
import queryString from "query-string";

import { axios } from "../api";

import type { AeronetV6ItemsResponse } from "../types";
import { ItemList } from "../inventory/types";

export interface ItemAutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
  pardId:number
  onChange: (event: React.SyntheticEvent, value: any) => void;
}

export function ItemAutocomplete<
  // eslint-disable-next-line
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>(
  props: ItemAutocompleteProps<
    ItemList,
    Multiple,
    DisableClearable,
    FreeSolo
  >
) {
  const { pardId,onChange,...autocompleteProps } = props;
  const { setFieldValue } = autocompleteProps.form;
  const [items, setItems] = useState<ItemList[]>([]);
  const [inputValue, setInputValue] = useState("");
  const { name } = fieldToTextField(props as any);
  const fetchInvoices = useMemo(
    () =>
      throttle((value: string, callback: Function) => {
        let params: { [key: string]: any } = {
          page: 1,
          size: 20,
          sort: "id",
          filter: [],
        };
       if (pardId > 0) {

        params['filter'].push(JSON.stringify({ field: "part_id",op:"==" ,value: pardId}));
        params['filter'].push(JSON.stringify({
          field: "status",
          op: "==",
          value: "stock",
        }));
       }
        if (value) {
          params["q"] = value;
        }
        axios
          .get("/v6/items", {
            params: params,
            paramsSerializer: (params) => queryString.stringify(params),
            id: `get-items-${pardId}`,
          })
          .then((resp: AxiosResponse<AeronetV6ItemsResponse<ItemList>>) => {
            axios.storage.remove(`get-items-${pardId}`)
            callback(resp.data.items);
          })
          .catch((error) => {
            callback([]);
          });
      }, 200),
    [pardId]
  );

  useEffect(() => {
    let active = true;

    fetchInvoices(inputValue, (results: ReadonlyArray<ItemList>) => {
      if (active) {
        let newInvoices: ItemList[] = [];

        if (results) {
          newInvoices = [...newInvoices, ...results];
        }

        setItems(newInvoices);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, fetchInvoices,pardId]);
  

  return (
    <MuiAutocomplete
      {...fieldToAutocomplete(autocompleteProps)}
      fullWidth
      options={items}
      autoComplete
      includeInputInList
      onInputChange={(_event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      disabled={pardId > 0 ? false : true}
      onChange={(event, newValue) => {
        if (
            newValue &&
            Object.prototype.hasOwnProperty.call(newValue, "id") &&
            // @ts-ignore
            newValue.id
          ) {
            // @ts-ignore
            setFieldValue("item_id", newValue.id);
            setFieldValue("item", newValue);
            onChange(event, newValue);
          } else {
            setFieldValue("item_id", "");
            setFieldValue("item", "");
          }
      }}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.id.toString() ?? ""
      }
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <MUITextField
          {...params}
          error={props.form.touched[name!] && !!props.form.errors[name!]}
          // @ts-ignore
          helperText={props.form.touched[name!] && props.form.errors[name!]}
          label="Item Number"
        />
      )}
      renderOption={(props, option: ItemList, state) => {
        // const matches = match(option.id.toString(), state.inputValue);
        // const invoices = parse(option.id.toString(), matches);

        return (
          <li {...props}>
            <Grid container alignItems="center">
              {/* <Grid item>
                <ReceiptIcon
                  sx={{
                    color: "text.secondary",
                    marginRight: 2,
                  }}
                />
              </Grid> */}
              <Grid item xs>
                {/* {invoices.map((invoice, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: invoice.highlight ? 700 : 400,
                    }}
                  >
                    {invoice.text}
                  </span>
                ))} */}
                 <Typography variant="body2" >
                   <b>Item Number: {option?.id} </b><br />
                   <b>Batch#:</b> {option?.batch?.number} / Expiry Date: {option?.shelf_expiry}<br />
                   <b>Location:</b> {option?.location} / <b>Store:</b> {option?.store} / <b>Shelf</b>:{option?.shelf}  <br />
                  <b>Quantity:</b> {option?.quantity} / <b>Condition:</b> {option?.condition} <br />
                  <b>Serial#:</b> {option?.serial_number} / Notes: {option?.notes}
                  </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}

ItemAutocomplete.displayName = "FormikMaterialUItemAutocomplete";
