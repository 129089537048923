import React, { lazy, MutableRefObject, useCallback, useEffect, useMemo, useState } from "react";
import { AeronetV6ItemsResponse, AeronetViewProps } from "../types";
import AeronetDataGrid, { QueryResults } from "../components/datagrid/DataGrid";
import {
  GridApi,
  GridColDef,
  GridFilterModel,
  GridSortItem,
  GridValueFormatterParams,
  ValueOptions,
} from "@mui/x-data-grid-pro";
import { AxiosResponse } from "axios";
import { InvoiceDeletePayload, InvoiceLines, InvoiceList } from "./types";
import { axios } from "../api";
import queryString from "query-string";
import { useSnackbar } from "notistack";
import { useCurrentUser } from "../hooks/useCurrentUser";
import { useNavigate } from "react-router-dom";
import { Button, IconButton ,Link as MaterialLink} from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { useGlobalConfig } from "../hooks/useGlobalConfig";
import { parseISO, format } from "date-fns";
import AlertDialog from "../components/AlertDialog";
import { ActionMenuItem } from "../components/datagrid/ActionMenu";
import InvoicePreview from "./InvoicePreview";
import { IDivision } from "../jobs/types";
const InvoiceAdvancedSearchForm = lazy(() => import("./AdvancedSearchForm"));

interface InvoiceDataGridProps extends AeronetViewProps {
  invoiceConfig: any;
  canCreateInvoice: boolean;
  canDeleteInvoice: boolean;
}

const InvoiceDataGrid: React.FC<InvoiceDataGridProps> = (props) => {
  const { invoiceConfig, canCreateInvoice, canDeleteInvoice } = props
  const { enqueueSnackbar } = useSnackbar()
  // const [invoice,SetInvoice] = useState<InvoiceList[]>([])
  const currentUser = useCurrentUser();
  const globalConfig = useGlobalConfig();
  const navigate = useNavigate();
  const [tabDialogOpen, setTabDialogOpen] = useState(false);
    const [selectedRows, setSelectedRows] = useState<InvoiceList[]>([]);
  const [deletePayload, setDeletePayload] =
    useState<InvoiceDeletePayload | null>(null);
  const [previewSelected, setPreviewSelected] = React.useState<number>();
    const [divisions,setDivisions] = useState<IDivision>()
    const [divisionsOptions,setDivisionsOptions] = useState<ValueOptions[]>([])
  useEffect(()=>{
    axios
    .get("/v6/jobs/divisions")
    .then((resp) => {
      let divisionList: ValueOptions[] = [];
      let divisionDict = {};
      divisionList = resp?.data?.data?.map((division:IDivision) => {
        // return 
        divisionDict[division.id] = division.name
        return {
          value: division.id,
          label: division.name,
        }
      })
      setDivisions(divisionDict as IDivision)
      setDivisionsOptions(divisionList);
      return null;
    })
    .catch((error) => {
      enqueueSnackbar(`Error fetching divisions `, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    });
  },[enqueueSnackbar])  
  const statusOptions: ValueOptions[] | undefined = useMemo(() => {
    if (invoiceConfig && invoiceConfig.status) {
      const statuses: ValueOptions[] = [{ value: "", label: "Any" }];
      Object.keys(invoiceConfig?.status).forEach((key) => {
        statuses.push({
          value: key,
          label: invoiceConfig?.status[key],
        });
      });
      return statuses.sort((a, b) =>
        // @ts-ignore
        a.label!.localeCompare(b.label!, "en", {
          sensitivity: "base",
        })
      );
    }
  }, [invoiceConfig]);
  const columns: GridColDef[] = useMemo(() => [
    {
      headerName: "ID",
      field: "id",
      flex: 1,
      filterable: false
    },
    {
      headerName: "Invoice To",
      field: "client_name",
      flex: 1,
      filterable: false
    },
    {
      headerName: "Date Created",
      field: "date_created",
      flex: 1,
      filterable: false,
      valueGetter: ({ value }) => value,
      valueFormatter: (params: GridValueFormatterParams) => {
        if (params.value && globalConfig) {
          return format(parseISO(params.value), globalConfig.date_format);
        }
        return params.value;
      },
    },
    {
      headerName: "Status",
      field: "status_id",
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams) => {
        try {
          if (invoiceConfig) {
            return invoiceConfig.status[
              Number(params.value as number)
            ].toUpperCase();
            }
          } catch {
            return params.value;
          }
        },
        type: "singleSelect",
        valueGetter: (params) => params.row?.status_id,
        filterable: true,
        valueOptions: statusOptions,
      },
    {
      headerName: "Order Number",
      field: "purchase_order",
      flex: 1,
      filterable: false
    },
    {
      headerName: "Division",
      field: "division",
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams) => {
        try {
          if (divisions) {
            return divisions[
              Number(params.value as number)
            ];
            }
          } catch {
            return params.value;
          }
        },
      filterable: true,
      valueOptions: divisionsOptions,
      type: "singleSelect",
    },
    {
      headerName: "Created By",
      field: "created_by_user.name",
      flex: 1,
      valueGetter: (params) => params.row?.created_by_user?.name,
      filterable: false
    },
    {
      headerName: "Reference",
      field: "reference",
      flex: 1,
      filterable: false
    },
    {
      headerName: "Total",
      field: "total",
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams) => {
        
        return params && Number(params?.value).toFixed( globalConfig?.value_rounding ?? 4)
      },
      headerAlign: "right",
      align: "right",
      valueGetter: (params) => params.row?.total,
      filterable: false
    },
    {
      headerName: "Currency",
      field: "currency",
      flex: 0.5,
      filterable: false
    },
    {
      headerName: "Print",
      field: "pdf",
      flex: 0.5,
      filterable: false,
      renderCell: (params) => {
        return (
          <IconButton
              className="pdf-icon-button"
              component={MaterialLink}
              rel="noopener noreferrer"
              size="small"
              onClick={() => {
                setPreviewSelected(params.row.id);
              }}
            >
              <PictureAsPdfIcon />
            </IconButton>
        );
      },
    }
  ], [invoiceConfig, globalConfig, statusOptions,divisions,divisionsOptions])




  const getData = useCallback(
    async (params: any, axiosConfig: any) => {
      try {
        const resp: AxiosResponse<AeronetV6ItemsResponse<InvoiceList>> =
          await axios.get("/v6/invoice", {
            params: params,
            paramsSerializer: (params) => queryString.stringify(params),
            ...axiosConfig,
          });

        return {
          total: resp.data.total,
          rows: resp.data.items,
        } as QueryResults<InvoiceList>;
      } catch (e) {
        // @ts-ignore
        if (!axios.isCancel(e)) {
          enqueueSnackbar("Error fetching sors", {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          });
        }
        throw e;
      }
    }, [enqueueSnackbar])

  const quickSearchItems = useMemo(
    () => [
      {
        field: "id",
        operator: "startsWith",
        label: "ID",
      },
      {
        field: "reference",
        operator: "contains",
        label: "Reference",
      },
      {
        field: "purchase_order",
        operator: "contains",
        label: "Order Number",
      },
    ],
    []
  );

  const advancedSearchDefaultItems = useMemo(
    () => [
      {
        id: 1,
        field: "id",
        operator: "is",
        value: "",
      },
      {
        id: 2,
        field: "purchase_order",
        operator: "contains",
        value: "",
      },
      {
        id: 3,
        field: "reference",
        operator: "contains",
        value: "",
      },
      {
        id: 4,
        field: "status_id",
        operator: "contains",
        value: "",
      },
      {
        id: 5,
        field: "division",
        operator: "contains",
        value: "",
      },
      {
        id: 6,
        field: "client_name",
        operator: "contains",
        value: "",
      },
      {
        id: 7,
        field: "created_by",
        operator: "contains",
        value: "",
      },
    ],
    []
  );
  console.log(canDeleteInvoice, "can delete invoice")

  const actions: ActionMenuItem[] = useMemo(() => {
    const defaultActions: ActionMenuItem[] = [

    ];
    if (canDeleteInvoice) {
      defaultActions.push({
        name: "Delete",
        namePlural: "Delete",
        action: ["Delete", "DELETE"],
        requiresSelection: true,
        multipleSelection: true,
        onClick: async (event: InvoiceDeletePayload) => {
          const rows = [...event.selectedRows] as InvoiceList[];
          for (let index = 0; index < rows.length; index++) {
            const row = rows[index];
            let lines: InvoiceLines[] = [];

            await axios
              .get(`/v6/invoice/lines/${row.id}`, {
                id: `get-lines-${row.id}`,
              })
              .then(
                (
                  resp: AxiosResponse<InvoiceLines>
                ) => {
                  axios.storage.remove(`get-lines-${row.id}`)
                  //@ts-ignore
                  lines = resp.data;
                }
              );
            console.log(lines, "lines")
            if (lines.length > 0) {
              enqueueSnackbar("Selected row has linked records", {
                variant: "warning",
              });
              return;
            }
          }
          setTabDialogOpen(true);
          setDeletePayload(event);
          setSelectedRows(rows);
        },
      });
    }

    return defaultActions;
  }, [canDeleteInvoice, enqueueSnackbar,]);


  const deleteInvoices = useCallback(() => {
    const invoiceIDs = selectedRows.map((row) => row.id);
    if (!invoiceIDs || invoiceIDs.length === 0) {
      return;
    }
    for (const id of invoiceIDs) {
      axios
        .delete(`/v6/invoice/${id}`)
        .then(() => {
          enqueueSnackbar(`Deleted invoices ${id}`, {
            variant: "success",
          });
          if (deletePayload?.apiRef) {
            deletePayload.apiRef.current.updateRows([
              { id: id, _action: "delete" },
            ]);
          }
        })
        .catch((_error) => {
          enqueueSnackbar("Error deleting invoices", {
            variant: "error",
          });
        });
    }
    deletePayload?.setSelectionModel([]);
    setDeletePayload(null);
    setSelectedRows([]);
  }, [deletePayload, enqueueSnackbar, selectedRows]);


  const onRowClick = useCallback(
    (params, event) => {
      event.stopPropagation();

      let currentElement = event.target;

      // Traverse up the DOM tree to check if any parent element has the 'pdf-icon-button' class
      while (currentElement) {
        // Ensure className is a string before calling includes
        if (
          typeof currentElement.className === "string" &&
          currentElement.className.includes("pdf-icon-button")
        ) {
          // If it's part of the PDF icon button, do not navigate
          return;
        }
        currentElement = currentElement.parentElement;
      }

      // If the clicked element is not part of the PDF icon, navigate
      navigate(String(params.id));
    },
    [navigate]
  );

  const onSearch = useCallback(
    (filterModel: GridFilterModel, apiRef: MutableRefObject<GridApi>) => {
      console.log("FilterModel....", filterModel);
      apiRef.current.setFilterModel(filterModel, "upsertFilterItems");
    },
    []
  );

  const initialSort: GridSortItem[] = useMemo(
    () => [{ field: "id", sort: "desc" }],
    []
  );


  const handleClose = useCallback(() => {
    setPreviewSelected(undefined);
  }, []);


  return (
    <>
      {previewSelected ? (
        <InvoicePreview invoiceId={previewSelected} onClose={handleClose} />
      ) : null}
      <AeronetDataGrid
        advancedSearchProps={{
          items: advancedSearchDefaultItems,
          onSearch: onSearch,
        }}
        quickSearchItems={quickSearchItems}
        columns={columns}
        data={getData}
        initialSort={initialSort}
        currentUserId={currentUser?.id}
        enableCreate={canCreateInvoice}
        name="invoice-data-grid"
        initialState={{
          columns: {
            columnVisibilityModel: false,
          },
        }}
        onRowClick={onRowClick}
        advancedSearchForm={InvoiceAdvancedSearchForm}
        disableColumnFilter={false}
        enableFilter={true}
        actions={actions}
      />
      <AlertDialog
        title="Delete this record?"
        open={tabDialogOpen}
        handleClose={() => {
          setTabDialogOpen(false);
        }}
      >
        <Button
          onClick={() => {
            setTabDialogOpen(false);
          }}
          color="primary"
        >
          No
        </Button>
        <Button
          onClick={() => {
            setTabDialogOpen(false);
            deleteInvoices();
          }}
          color="primary"
          autoFocus
        >
          Yes
        </Button>
      </AlertDialog>
    </>
  );
};


export default InvoiceDataGrid;
