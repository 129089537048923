import { AxiosResponse, Method } from 'axios';
import React, { useCallback, useEffect, useMemo, useRef, useState, useTransition } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { InvoiceDetails, InvoiceLines } from './types';
import { axios, useQuery } from '../api';
import {  useSnackbar } from 'notistack';
import { Field, Form, Formik, FormikValues } from 'formik';
import Header from '../components/Header';
import { CreateFab, SaveFab } from '../components/Buttons';
import { TabBar, TabPanel } from '../components/Tabs';
import { Box,  Grid, MenuItem, Skeleton, Tab,  Typography ,  Menu, Button, IconButton} from '@mui/material';
import { useConfig } from '../hooks/useConfig';
import { Select, TextField } from 'formik-mui';
import { FormDivider } from '../components/Form';
import Content from '../components/Content';
import { DatePicker } from 'formik-mui-x-date-pickers';
import {  CompanyAutocomplete } from '../companies/FormikCompanyAutocomplete';
import { CompanyV6 } from '../companies/types';
import { merge } from 'lodash';
import { UserAutocomplete } from '../users/user-management/UserAutocomplete';
import { IAddress, IDivision } from '../jobs/types';
import { OrderType } from '../orders/types';
import { useTypeOptions } from '../hooks/useTypeOptions';
import { useGlobalConfig } from '../hooks/useGlobalConfig';
import { getCurrencies } from '../currency/currency';
import {  Currency } from '../types';
import CustomSnackbarContent from '../components/CustomSnackbarContent';
import { handleAPIErrors } from '../utils/FormikErrors';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import {GridCallbackDetails, GridCellSelectionModel, GridColDef, GridRowSelectionModel,GridCellParams, GridToolbarContainer, GridToolbarFilterButton, GridToolbarExport } from '@mui/x-data-grid-premium'
import { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { ActionMenuItem } from '../components/datagrid/ActionMenu';
import { grey } from "@mui/material/colors";
import { DataGridPremium } from '@mui/x-data-grid-premium';
import InvoiceEditPart from './InvoiceEditPart';
import LightTooltip from '../components/LightTooltip';
import AlertDialog from '../components/AlertDialog';
import CustomFilterPanelPremium from '../components/CustomFilterPanelPremium';
import InvoicePart from './InvoicePart';
import { ValueOptions } from "@mui/x-data-grid-pro";
import { useCurrentUser } from '../hooks/useCurrentUser';
interface InvoiceDetailProps {
    originalUrl:any
}


function a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const parseAddress3 = (data?: string) => {
    let city: string = "";
    let state: string = "";
    let post_code: string = "";
  
    if (data) {
      const split_address_3 = data.split("-").map((item) => item.trim());
      if (split_address_3.length === 1) {
        city = split_address_3[0];
      } else if (split_address_3.length === 2) {
        city = split_address_3[0];
        state = split_address_3[1];
        post_code = split_address_3[1];
      } else if (split_address_3.length === 3) {
        city = split_address_3[0];
        state = split_address_3[1];
        post_code = split_address_3[2];
      }
    }
  
    return [city, state, post_code];
  }


const setAdress3 = (data?: IAddress | null) => {
  let addrline3: string = "";
  if (data?.city) {
    addrline3 = data?.city;
  }

  if (data?.state && !["NZ", "New Zealand"].includes(data?.country || "")) {
    addrline3 = `${addrline3} - ${data?.state}`;
  }

  if (data?.post_code) {
    addrline3 = `${addrline3} - ${data?.post_code}`;
  }

  return addrline3;
}

  const mappingInvoiceData = (data) => {
    const invoice: Partial<InvoiceDetails> = {};
  
    for (const [key, value] of Object.entries(data)) {
      if (value === null) {
        invoice[key] = "";
      } else {
        invoice[key] = value;
      }
    }
    invoice["client"] = {
      id: parseInt(data["client_id"]),
      name: data["client_name"],
    };
  
    invoice["deliver"] = {
      id:parseInt(data["company_id"])> 0 ? parseInt(data["company_id"]) : 0,
      name: parseInt(data["company_id"])> 0? data["deliver_to"] : "",
    };
  
    const parsed_address_line3_result = parseAddress3(data["client_address_3"]);
    invoice["client_address"] = {
      line1: data["client_address_1"],
      line2: data["client_address_2"],
      city: parsed_address_line3_result[0],
      state: parsed_address_line3_result[1],
      post_code: parsed_address_line3_result[2],
      country: data["client_address_4"],
    };
  

  
      const parsed_deliver_address_line3_result = parseAddress3(
        data["address_line_3"]
      );
      invoice["deliver_address"] = {
        line1: data["address_line_1"],
        line2: data["address_line_2"],
        city: parsed_deliver_address_line3_result[0],
        state: parsed_deliver_address_line3_result[1],
        post_code: parsed_deliver_address_line3_result[2],
        country: data["address_line_4"],
      };
  
     console.log(invoice,"invoice")
  
  
    return invoice;
  };
  

const InvoiceDetail:React.FC<InvoiceDetailProps> = (props) => {
    const {id} = useParams()
    let isRendered = useRef(false);
    const [invoiceDetail,setInvoiceDetail] = useState<InvoiceDetails | null>(null)
    const { enqueueSnackbar } = useSnackbar()
    const [, startTransition] = useTransition();
    const [title,setTitle] = useState('')
    const [tabValue, setTabValue] = useState(0);
    const formRef = useRef<FormikValues>()
    const invoiceConfig = useConfig('invoices')
    const [divisions,setDivisions] = useState<IDivision[]>([])
    const [handleLinesSubmit,setHandleLinesSubmit] = useState<boolean>(false)
    const navigate = useNavigate();
    const currentUser = useCurrentUser();
    const globalConfig = useGlobalConfig();
    useEffect(() => {
        if (id) {
          if (invoiceDetail && "id" in invoiceDetail) {
            setTitle(`Invoice ${invoiceDetail.id}`);
          }
        }
      }, [id, invoiceDetail]);
    useEffect(()=>{
      isRendered.current = true
      axios
      .get("/v6/jobs/divisions")
      .then((resp) => {
        if (isRendered.current) {
          setDivisions(resp.data.data);
        }
        return null;
      })
      .catch((error) => {
        enqueueSnackbar(`Error fetching divisions `, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        });
      });
    },[enqueueSnackbar])  
    useEffect(() => {
        isRendered.current = true;
        if (id) {
          axios
            .get(`/v6/invoice/${id}`, { id: `get-invoice-${id}` })
            .then((resp: AxiosResponse<InvoiceDetails>) => {
              if (isRendered.current) {
                // for (const value in resp.data) {
                //     invoice[value] = resp.data[value] === null ? "" : resp.data[value];
                // }
                const invoice = mappingInvoiceData(resp.data);
                console.log(invoice,"invoice")
                setInvoiceDetail(invoice as InvoiceDetails);
              }
    
              return null;
            })
            .catch((error) => {
              enqueueSnackbar(`Error fetching sor`, {
                variant: "error",
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center",
                },
              });
              console.error(error);
            });
        } else {

          const newOrder: InvoiceDetails = {
            account_status: "",
            city: "",
            client_name: "",
            country: "",
            deliver_to: "",
            delivery_code: "",
            delivery_cost:  0,
            delivery_total:  0,
            exchange: String((1).toFixed(globalConfig?.exchange_rounding)),
            exported:  0,
            gst_rate:  0,
            locked_by:  0,
            reference: "",
            ship_via: "",
            stationery: "NZ",
            user_id:0,
            active: true,
            status_id:1,
            company_id:0,
            client_id: 0,
            modified: new Date(),
            type_id: 0,
            readonly: new Date(),
            processed: "",
            purchase_order: "",
            created: new Date(),
            total: 0,
            date_bill: new Date(),
            paid_ref: "",
            paid:0,
            description: "",
            gst_total: 0,
            currency_code: "USD",
            comment: "",
            discount: 0,
            division: 0,
            address_line_1:"",
            address_line_2:"",
            address_line_3:"",
            address_line_4:"",
            client_address_1:"",
            client_address_2:"",
            client_address_3:"",
            client_address_4:"",
            export_code:"",
            source_id:"",
            aircraft_notes:"",
            client:{
              id: 0,
              name: "",
            },
            deliver:{
              id: 0,
              name: "",
            },
            company:{
              id: currentUser?.company?.id ?? 0,
              name: currentUser?.company?.name ?? "",
            },
            division_obj:null,
            lines:null,
            deliver_address:{},
            client_address:{},
            division_id:invoiceConfig?.default_division ?? 0,
            created_by_user:{
              id:currentUser?.id,
              name:currentUser?.name ?? ""
            }
          };
          startTransition(() => {
            setInvoiceDetail(newOrder);
            setTitle("New Invoice");
          });
        }
        return () => {
          isRendered.current = false;
        };
      }, [id, enqueueSnackbar,invoiceConfig,currentUser,globalConfig]);

      const handleTabChange = (
        _event: React.SyntheticEvent<{}> | null,
        newValue: unknown
      ) => {
        if (formRef.current && !formRef.current.initialValues.id) {
          setTabValue(newValue as number);
        //   submitForm();
        }
        setTabValue(newValue as number);
      };
    
  

    const submitForm = () => {
      if (formRef.current) {
        formRef.current.submitForm().then(() => {
          if (
            formRef.current &&
            !formRef.current.isValidating &&
            formRef.current.isValid
          ) {
            navigate(-1);
          }
        });
      }
    else{
      setHandleLinesSubmit(true)
      navigate(-1)
    }
    };
    
    const saveForm = () => {
      if (formRef.current) {
        formRef.current.handleSubmit()
      }
      else{
        setHandleLinesSubmit(true)
      }
    };

  return (
   <>
    <Header actions={[<SaveFab onClick2={saveForm} onClick={submitForm} key={1} />]}>
        {title}
      </Header>
      <TabBar value={tabValue} onChange={handleTabChange}>
        <Tab disableRipple label="General" {...a11yProps(0)} />
        <Tab disableRipple label="Parts" {...a11yProps(1)} />
      </TabBar>
   <Content>

   {(invoiceDetail && divisions) &&
     <TabPanel  value={tabValue} index={0}>
        <General invoiceConfig={invoiceConfig} formRef={formRef} setInvoiceDetail = {setInvoiceDetail}  invoiceDetail={invoiceDetail} Divisions = {divisions} />
      </TabPanel>
      }
       {(invoiceDetail?.lines) &&  <TabPanel  value={tabValue} index={1}>
        <InvoicePartTab  invoiceDetail={invoiceDetail} handleLinesSubmit = {handleLinesSubmit} setHandleLinesSubmit = {setHandleLinesSubmit} />
      </TabPanel>}
   </Content>
   </>
  )
}

interface InvoiceGeneralProps {
    invoiceConfig: any;
    formRef: any;
    invoiceDetail:InvoiceDetails;
    Divisions:IDivision[];
    setInvoiceDetail:any
  }

const General = (props:InvoiceGeneralProps)=>{
    const {formRef,invoiceDetail,invoiceConfig, Divisions ,setInvoiceDetail} = props
    const [isEditCompany, setIsEditCompany] = useState<boolean>(false);
    const [currentDeliverAddressLineCountry, setCurrentDeliverAddressLineCountry] = useState(invoiceDetail.address_line_4);
      const [currentSupplierAddressLineCountry, setCurrentsupplierAddressLineCountry] = useState(invoiceDetail.client_address_4);
    const globalConfig = useGlobalConfig();
    const [currency] = useQuery(getCurrencies);
    const currentUser = useCurrentUser();
    const location = useLocation()
    const companyCountryOptions =
    useTypeOptions(invoiceConfig?.country_parent) ?? [];
    const {enqueueSnackbar} = useSnackbar()
    const ordersConfig = useConfig("orders")
    const stationeryOptions: ValueOptions[] | null = useMemo(() => {
      if (invoiceConfig && invoiceConfig.stationery) {
        const opts: ValueOptions[] = [];
        Object.keys(invoiceConfig?.stationery).forEach((key) => {
          opts.push({
            value: key,
            label: invoiceConfig?.stationery[key],
          });
        });
        return opts;
      }
      return null;
    }, [invoiceConfig]);
    const shipViaOptions = useTypeOptions(ordersConfig?.ship_via_parent_id);



  const onSaveDataAddress = useCallback((values) => {

    let jobData = {
      
      client_id: values?.client?.id,
      client_name: values?.client?.name,
      client_address_1: values?.client_address?.line1,
      client_address_2: values?.client_address?.line2,
      client_address_3: setAdress3(values?.client_address),
      client_address_4: values?.client_address?.country,


      company_id:values?.deliver?.id,
      deliver_to:values?.deliver?.name,
      address_line_1:values.deliver_address?.line1,
      address_line_2: values.deliver_address?.line2,
      address_line_3: setAdress3(values.deliver_address),
      address_line_4:values.deliver_address?.country,
    };
    console.log(jobData,"job data")
    let method: Method;
    let url: string;
    let msg: string;

    if (Object.prototype.hasOwnProperty.call(invoiceDetail, "id") && invoiceDetail.id) {
      method = "put";
      url = `/v6/invoice/${invoiceDetail.id}`;
      msg = "updated";
    } else {
      method = "post";
      url = "/v6/invoice";
      msg = "created";

      invoiceDetail["deliver_to"] = invoiceDetail["company"]?.["name"] || "";
      invoiceDetail["created_by"] = invoiceDetail["created_by_user"]?.["id"];
    }
setIsEditCompany(false)
    axios({
      method: method,
      url: url,
      data: jobData,
      cache: {
        update: {
          [`get-invoice-${invoiceDetail.id}`]: "delete",
        },
      },
    })
      .then((resp) => {
        axios.storage.remove(`get-invoice-${resp.data.id}`)
        setIsEditCompany(false);
        const invoice = mappingInvoiceData(resp.data);
        setInvoiceDetail(invoice);

        enqueueSnackbar(
          <CustomSnackbarContent
            message={`Successfully ${msg} job (${resp.data.id})`}
          />,
          {
            variant: "success",
          }
        );
      })
      .catch((error) => {
        enqueueSnackbar("Error saving job", {
          variant: "error",
        });
      });
  }, [enqueueSnackbar, invoiceDetail,setInvoiceDetail]);


    return(
    <>
    <Formik
    enableReinitialize
    innerRef={formRef}
    initialValues={invoiceDetail}
    onSubmit={(values, { setSubmitting, setErrors, resetForm  })=>{

        console.log(values ,"values")

        let method: Method = "post";
                let url:string = `/v6/invoice`;
                let msg: string = "created";


        if (Object.prototype.hasOwnProperty.call(invoiceDetail, "id") && invoiceDetail.id) {
            method = "put";
            url = `/v6/invoice/${invoiceDetail.id}`;
            msg = "updated";
        }

        let invoiceData = Object.entries(values).reduce((acc,[key,value])=>{

          if(key === 'client')
          {
            acc['client_id'] = value?.id || currentUser?.company?.id || 0
            acc['client_name'] = value?.name || ""
          }
          else if(key === 'deliver'){
            acc['company_id'] = value?.id || currentUser?.company?.id || 0
            acc['deliver_to'] = value?.name || ""
          }
          else if (key === "locked_by")
          {
            if (value === '')
            {
              acc[key] =0
            }
            else{
              acc[key] = Number(value)
            }
          }
          else if (key === 'purchase_order')
          {
            acc[key] = value
          }
          else{
            acc[key] = value
          }

          return acc
        },{} as InvoiceDetails)

         if (isEditCompany) {
          let newData = {
            client_address_1: invoiceDetail?.client_address?.line1,
            client_address_2: invoiceDetail?.client_address?.line2,
            client_address_3: setAdress3(invoiceDetail?.client_address),
            client_address_4: invoiceDetail?.client_address?.country,
            
            address_line_1:invoiceDetail.deliver_address?.line1,
            address_line_2: invoiceDetail.deliver_address?.line2,
            address_line_3: setAdress3(invoiceDetail.deliver_address),
            address_line_4:invoiceDetail.deliver_address?.country,
          };
          invoiceData = merge(invoiceData, newData);
        }
        console.log(invoiceData ,'invoiceData')
        axios({
          method: method,
          url: url,
          data: invoiceData,
          cache: {
            update: {
              [`get-invoice-${invoiceData.id}`]: "delete",
            },
          },
      }).then((resp: AxiosResponse<InvoiceDetails>) => {
        axios.storage.remove(`get-invoice-${resp.data.id}`)
        const invoice = mappingInvoiceData(resp.data);
        setInvoiceDetail(invoice);
          setSubmitting(false);
          let uri = location.pathname;
          uri = uri.replace("create",`${resp.data.id}`)
          axios.storage.remove(`get-invoice-${invoiceDetail.id}`)
          
          enqueueSnackbar(
            <CustomSnackbarContent
              message={`Successfully ${msg} invoice`}
              uri={uri}
            />,
            {
              variant: "success",
            }
          );
          axios.storage.remove(`get-sor-${resp.data.id}`);
          resetForm();


      }).catch((error) => {
          handleAPIErrors(error, setErrors);
          setSubmitting(false);
          enqueueSnackbar(`Error saving invoice`, {
            variant: "error",
          });
      });
    }}
    >
      {({ isSubmitting, setFieldValue, values, setValues,handleBlur }) => (
         <Form className='mui-form'>
         <Grid container spacing={6}>
            <Grid item xs={12} md={4} lg={4}>
            <Field
                autoFocus
                required
                fullWidth
                component={CompanyAutocomplete}
                companyType={OrderType.Customer}
                label="Invoice To"
               
                name="client"
                onChange={(event, value: CompanyV6 | null) => {
                  console.log(value,"value")
                  setCurrentDeliverAddressLineCountry(value?.delivery_address_country ?? '')
                  setCurrentsupplierAddressLineCountry(value?.postal_address_country ?? '')
                  const newClientAddress = {
                    line1: value?.postal_address_line_1 || "",
                    line2: value?.postal_address_line_2 || "",
                    city: value?.postal_address_city || "",
                    state: value?.postal_address_region || "",
                    post_code: value?.postal_address_postcode || "",
                    country: value?.postal_address_country || "",
                   };
                  const newDeliverAddress = {
                    line1: value?.delivery_address_line_1 || "",
                    line2: value?.delivery_address_line_2 || "",
                    city: value?.delivery_address_city || "",
                    state: value?.delivery_address_region || "",
                    post_code: value?.delivery_address_postcode || "",
                    country: value?.delivery_address_country || "",
                  }
                  if (!values?.deliver?.id && !values?.client?.id) {
                    const intialMerge = merge(values, {
                      clinet_address:newClientAddress,
                      client:{
                        id: value?.id || 0,
                        name: value?.name || "",
                      },
                      client_address_1: value?.postal_address_line_1 || "",
                      client_address_2: value?.postal_address_line_2 || "",
                      client_address_3: setAdress3(newClientAddress),
                      client_address_4: value?.postal_address_country || "",
                      deliver: {
                        id: value?.id || 0,
                        name: value?.name || "",
                      },
                      deliver_address:newDeliverAddress,
                      address_line_1: value?.delivery_address_line_1 || '',
                      address_line_2: value?.delivery_address_line_2 || '',
                      address_line_3: setAdress3(newDeliverAddress) || '',
                      address_line_4: value?.delivery_address_country || '',
                    });
                    setValues(intialMerge);
                  }
                  else{
                    const merged = merge(values, {
                      clinet_address:newClientAddress,
                      client:{
                        id: value?.id || 0,
                        name: value?.name || "",
                      },
                      client_address_1: value?.postal_address_line_1 || "",
                      client_address_2: value?.postal_address_line_2 || "",
                      client_address_3: setAdress3(newClientAddress),
                      client_address_4: value?.postal_address_country || "",
                     
                    });
                    setValues(merged);
                  }
                 
                  console.log(values,"values")
                }}
              />
            {isEditCompany ? (
                <>
                  <Field
                    fullWidth
                    component={TextField}
                    type="text"
                    label="Line 1"
                    name="client_address.line1"
                    InputLabelProps={{
                      shrink: values?.client_address?.line1,
                    }}
                    onChange={(event) => {
                      const merged = merge(values, {
                        client_address: {
                          ...values.client_address,
                          line1: event.target.value,
                        },
                      });
                      setValues(merged);
                    }}
                  />
                  <br />
                  <Field
                    fullWidth
                    component={TextField}
                    type="text"
                    label="Line 2"
                    name="client_address.line2"
                    InputLabelProps={{
                      shrink: values?.client_address?.line2,
                    }}
                    onChange={(event) => {
                      const merged = merge(values, {
                        client_address: {
                          ...values.client_address,
                          line2: event.target.value,
                        },
                      });
                      setValues(merged);
                    }}
                  />
                  <br />
                  <Field
                    fullWidth
                    component={TextField}
                    type="text"
                    label="Town / City"
                    name="client_address.city"
                    InputLabelProps={{
                      shrink: values?.client_address?.city,
                    }}
                    onChange={(event) => {
                      const merged = merge(values, {
                        client_address: {
                          ...values.client_address,
                          city: event.target.value,
                        },
                      });
                      setValues(merged);
                    }}
                  />
                  <br />
                  <Field
                    fullWidth
                    component={TextField}
                    type="text"
                    label="State / Region"
                    name="client_address.state"
                    InputLabelProps={{
                      shrink: values?.client_address?.state,
                    }}
                    onChange={(event) => {
                      const merged = merge(values, {
                        client_address: {
                          ...values.client_address,
                          state: event.target.value,
                        },
                      });
                      setValues(merged);
                    }}
                  />
                  <br />
                  <Field
                    fullWidth
                    component={TextField}
                    type="text"
                    label="Post Code"
                    name="client_address.post_code"
                    InputLabelProps={{
                      shrink: values?.client_address?.post_code,
                    }}
                    onChange={(event) => {
                      const merged = merge(values, {
                        client_address: {
                          ...values.client_address,
                          post_code: event.target.value,
                        },
                      });
                      setValues(merged);
                    }}
                  />
                  <br />
                  {companyCountryOptions.length ? (
                    <Field
                      component={Select}
                      label="Country"
                      id="client_address.country"
                      name="client_address.country"
                      onChange={(event) => {
                        setCurrentsupplierAddressLineCountry(event.target.value)
                        const merged = merge(values, {
                          client_address: {
                            ...values.client_address,
                            country: event.target.value,
                          },
                        });
                        setValues(merged);
                      }}
                      value={currentSupplierAddressLineCountry}
                    >
                      {companyCountryOptions.map((company) => (
                        //@ts-ignore
                        <MenuItem value={company.label} key={company.value}>
                          {/*@ts-ignore*/}
                         {company.label}
                        </MenuItem>
                      ))}
                    </Field>
                  ) : (
                    <Field
                      fullWidth
                      component={TextField}
                      type="text"
                      label="Country"
                      name="client_address.country"
                      InputLabelProps={{
                        shrink: values?.client_address?.country,
                      }}
                      onChange={(event) => {
                        const merged = merge(values, {
                          client_address: {
                            ...values.client_address,
                            country: event.target.value,
                          },
                        });
                        setValues(merged);
                      }}
                    />
                  )}
                </>
                ) : (
                <Box pl={1.5}>
                   <>
                      <Typography>{values.client_address_1}</Typography>
                      <Typography>{values.client_address_2}</Typography>
                      <Typography>{values.client_address_3}</Typography>
                      <Typography>{values.client_address_4}</Typography>
                    </>
                      <Typography>
                        <strong>Account Status: </strong>
                        {values.account_status}
                      </Typography>
                 
                  
                </Box>
            )} 
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Field
                required
                fullWidth
                component={CompanyAutocomplete}
                companyType={OrderType.Customer}
                label="From"
                name="deliver"
                onChange={(event, value: CompanyV6 | null) => {
                  setCurrentDeliverAddressLineCountry(value?.delivery_address_country ?? '')
                  const newDeliverAddress = {
                    line1: value?.delivery_address_line_1 || '',
                    line2: value?.delivery_address_line_2 || '',
                    city: value?.delivery_address_city || '',
                    state: value?.delivery_address_region || '',
                    post_code: value?.delivery_address_postcode || '',
                    country: value?.delivery_address_country || '',
                  };
                  const merged = merge(values, {
                    deliver: {
                      id: value?.id || 0,
                      name: value?.name || '',
                    },
                    deliver_address: newDeliverAddress,
                    address_line_1: value?.delivery_address_line_1 || '',
                    address_line_2: value?.delivery_address_line_2 || '',
                    address_line_3: setAdress3(newDeliverAddress) || '',
                    address_line_4: value?.delivery_address_country || '',
                  });
                  setValues(merged);
                  console.log(values,"values")
                }}
              />
              {isEditCompany ? (
                <>
                  <Field
                    fullWidth
                    component={TextField}
                    type="text"
                    label="Line 1"
                    name="deliver_address.line1"
                    InputLabelProps={{
                      shrink: values?.deliver_address?.line1  // Display label for the field
                    }}
                    onChange={(event) => {
                      const merged = merge(values, {
                        deliver_address: {
                          ...values.deliver_address,
                          line1: event.target.value,
                        },
                      });
                      setValues(merged)
                    }}
                  />

                  <Field
                    fullWidth
                    component={TextField}
                    type="text"
                    label="Line 2"
                    name="deliver_address.line2"
                    InputLabelProps={{
                      shrink: values?.deliver_address?.line2 ,
                    }}
                    onChange={(event) => {
                      const merged = merge(values, {
                        deliver_address: {
                          ...values.deliver_address,
                          line2: event.target.value,
                        },
                      });
                      setValues(merged)
                    }}
                  />
                  <br />
                  <Field
                    fullWidth
                    component={TextField}
                    type="text"
                    label="Town / City"
                    name="deliver_address.city"
                    InputLabelProps={{
                      shrink: values?.deliver_address?.city
                    }}
                    onChange={(event) => {
                      const merged = merge(values, {
                        deliver_address: {
                          ...values.deliver_address,
                          city: event.target.value,
                        },
                       
                      });
                      setValues(merged)
                    }}
                  />
                  <br />
                  <Field
                    fullWidth
                    component={TextField}
                    type="text"
                    label="State / Region"
                    name="deliver_address.state"
                    InputLabelProps={{
                      shrink: values?.deliver_address?.state
                    }}
                    onChange={(event) => {
                      const merged = merge(values, {
                        deliver_address: {
                          ...values.deliver_address,
                          state: event.target.value,
                        },
                       
                      });
                      setValues(merged);
                    }}
                  />
                  <br />
                  <Field
                    fullWidth
                    component={TextField}
                    type="text"
                    label="Post Code"
                    name="deliver_address.post_code"
                    InputLabelProps={{
                      shrink: values?.deliver_address?.post_code
                    }}
                    onChange={(event) => {
                      const merged = merge(values, {
                        deliver_address: {
                          ...values.deliver_address,
                          post_code: event.target.value,
                        },
                      });
                      setValues(merged);
                    }}
                  />
                  <br />
                  {companyCountryOptions.length ? (
                    <Field
                      component={Select}
                      label="Country"
                      id="deliver_address.country"
                      name="deliver_address.country"
                      onChange={(event) => {
                        setCurrentDeliverAddressLineCountry(event.target.value)
                        const merged = merge(values, {
                          deliver_address: {
                            ...values.deliver_address,
                            country: event.target.value,
                          },
                        });
                        setValues(merged);
                      }}
                      value={currentDeliverAddressLineCountry}
                    >
                      {companyCountryOptions.map((company) => (
                        // @ts-ignore
                        <MenuItem value={company.label} key={company.value}>
                          {/* @ts-ignore */}
                          {company.label}
                        </MenuItem>
                      ))}
                    </Field>
                  ) : (
                    <Field
                      fullWidth
                      component={TextField}
                      type="text"
                      label="Country"
                      name="deliver_address.country"
                      InputLabelProps={{
                        shrink: values?.deliver_address?.country,
                      }}
                      onChange={(event) => {
                        const merged = merge(values, {
                          deliver_address: {
                            ...values.deliver_address,
                            country: event.target.value,
                          },
                        });
                        setValues(merged);
                      }}
                    />
                  )}
                  <div style={{ display: "flex", justifyContent: "right", marginTop: '6px' }}>
                    <Button
                      color="secondary"
                      variant="outlined"
                      onClick={() => setIsEditCompany(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="secondary"
                      variant="outlined"
                      onClick={() => onSaveDataAddress(values)}
                      style={{ marginLeft: "10px" }}
                    >
                      Save
                    </Button>
                  </div>
                </>
              ) :
                <Box pl={1.5}>
                  <Typography>{values?.address_line_1}</Typography>
                  <Typography>{values?.address_line_2}</Typography>
                  <Typography>{values?.address_line_3}</Typography>
                  <Typography>{values?.address_line_4}</Typography>
                 
                  <div style={{ display: "flex", justifyContent: "right" }}>
                    <Button
                      fullWidth={false}
                      color="secondary"
                      variant="outlined"
                      onClick={() => setIsEditCompany(true)}
                    >
                      Edit
                    </Button>
                  </div>
                </Box>
              }
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              {invoiceConfig?.status ? (
                <>
                  <Field
                    component={Select}
                    id="status"
                    name="status_id"
                    label="Status"
                  >
                    {Object.entries(invoiceConfig.status).map(([key, value]) => (
                      <MenuItem value={key} key={key}>
                        {value as unknown as string}
                      </MenuItem>
                    ))}
                  </Field>
                  <br />
                </>
              ) : (
                <></>
              )}
              <Field
                component={Select}
                id="division"
                name="division_id"
                label="Division"
              >
                {Divisions.map((division) => (
                  <MenuItem value={division.id} key={division.id}>
                    {division.name}
                  </MenuItem>
                ))}
              </Field>
              <br />
              <Field
                component={UserAutocomplete}
                label="Created by"
                name="created_by_user"
              />
              {globalConfig ? (
                <Field
                  component={DatePicker}
                  label="Date Created"
                  name="date_entered"
                  formControl={{ required: true }}
                  inputFormat={globalConfig?.date_format}
                  fullWidth
                />
              ) : (
                <Skeleton animation="wave" sx={{ fontSize: "2rem" }} />
              )}
              <br />
            </Grid>
          </Grid> 
            <Grid container spacing={4}>

            <FormDivider>Details</FormDivider>
                <Grid  item xs={12} md = {4} lg = {4}>
                    <Field
                    fullWidth
                    label = "Aircraft"
                    component = {TextField}
                    name = "aircraft_notes"
                    />
                    <br /> 
                    <Field
                    fullWidth
                    label = "Order Number"
                    component = {TextField}
                    name = "purchase_order"
                    />
                    <br />
                    <Field
                    fullWidth
                    label = "Source Reference"
                    component = {TextField}
                    name = "source_id"
                    />
                    <br />
                </Grid>
                 <Grid  item xs={12} md = {4} lg = {4}>
                 {shipViaOptions ? (
                    <Field component={Select} name="ship_via" label="Ship via">
                      {shipViaOptions.map((option) => (
                        // @ts-ignore
                        <MenuItem value={option.value} key={option.value}>
                          {/* @ts-ignore */}
                          {option.label}
                        </MenuItem>
                      ))}
                    </Field>
                  ) : (
                    <Skeleton animation="wave" sx={{ fontSize: "2rem" }} />
                  )}

                    <Field
                    fullWidth
                    label = "Reference"
                    component = {TextField}
                    name = "reference"
                    />
                    <br />
                    {/* <Field
                    fullWidth
                    label = "Stationery"
                    component = {TextField}
                    name = "stationery"
                    />
                    <br /> */}
                    {stationeryOptions ? (
                      <Field component={Select} name="stationery" label="Stationery">
                        {stationeryOptions.map((option) => (
                          // @ts-ignore
                          <MenuItem value={option.value} key={option.value}>
                            {/* @ts-ignore */}
                            {option.label}
                          </MenuItem>
                        ))}
                      </Field>
                    ) : (
                      <Skeleton animation="wave" sx={{ fontSize: "2rem" }} />
                    )}
                </Grid>  
                <Grid  item xs={12} md = {1} lg = {1}>
                {currency ? (
                <Field
                component={Select}
                id="currency"
                name="currency_code"
                label="Currency"
              >
                <MenuItem value="" key={0} disabled>
                  Currency
                </MenuItem>
                {currency.data.map((currency: Currency, index) => (
                  <MenuItem value={currency.currency_short} key={index + 1}>
                    {currency.currency_short}
                  </MenuItem>
                ))}
              </Field>
              ) : (
                <Skeleton />
              )}
                    <br /> 
                    <Field
                    fullWidth
                    label = "Paid"
                    component = {TextField}
                    name = "paid"
                    />
                    <br />
                    <Field
                    fullWidth
                    label = "Export Batch"
                    component = {TextField}
                    name = "exported"
                    />
                    <br />
                </Grid>
                <Grid  item xs={12} md = {3} lg = {3}>
                  <Field
                    fullWidth
                    label = "Exchange Rate"
                    component = {TextField}
                    name = "exchange"
                    inputProps={{ inputMode: "decimal" }}
                    onBlur={(event) => {
                      const formatted = formatValue(values.exchange!, globalConfig?.value_rounding ?? 4);
                      setFieldValue("exchange", formatted);
                      handleBlur(event);
                    }}
                    />
                    <br /> 
                    <Field
                    fullWidth
                    label = "Payment Reference"
                    component = {TextField}
                    name = "paid_ref"
                    />
                    <br />
                    <Field
                    fullWidth
                    label = "Export Code"
                    component = {TextField}
                    name = "export_code"
                    />
                    <br />
                </Grid>
            </Grid>
            <Grid container spacing={4} >

            <FormDivider>Additional Information</FormDivider>
                <Grid item xs={12} md={4} lg={4}>
                <Field
                    fullWidth
                    multiline
                    rows="5"
                    label = "Client Comments"
                    component = {TextField}
                    name = "comment"
                    />
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                <Field
                    fullWidth
                    multiline
                    rows="5"
                    label = "Inhouse Notes"
                    component = {TextField}
                    name = "description"
                    />
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                <Field
                    fullWidth
                    multiline
                    rows="5"
                    label = "Conditions"
                    component = {TextField}
                    name = "comment"
                    />
                </Grid>
            </Grid>
        </Form>
      )}
    </Formik>
    </>
    )
}

interface ActionMenuButtonProps {
  onClick?: (event: any) => any;
  actions?: ActionMenuItem[];
  disabled?: boolean;
}

const ActionMenu: React.FC<ActionMenuButtonProps> = (
  props: ActionMenuButtonProps
) => {
  const { actions, disabled } = props;
  const popupState = usePopupState({
    variant: "popover",
    popupId: "action-menu",
  });

  return (
    <>
      <ActionMenuButton disabled={disabled} {...bindTrigger(popupState)} />
      <Menu {...bindMenu(popupState)}>
        {actions?.map((action, index) => {
          if (action.to) {
            return (
              <MenuItem
                onClick={() => popupState.close()}
                component={Link}
                to={action.to}
                key={index}
              >
                {action.name}
              </MenuItem>
            );
          }
          return (
            <MenuItem
              key={index}
              onClick={() => {
                popupState.close();
                if (action.onClick) {
                  action.onClick();
                }
              }}
            >
              {action.name}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

const ActionMenuButton: React.FC<ActionMenuButtonProps> = (
  props: ActionMenuButtonProps
) => {
  const { onClick, disabled } = props;

  return (
    <Button
      disabled={disabled}
      aria-controls="options-menu"
      aria-haspopup="true"
      onClick={onClick}
      sx={{
        color: { sm: "white" },
        mx: { xs: 1, sm: 1.5 },
        mt: 0.5,
        backgroundColor: { sm: grey[500] },
        "&:hover": { backgroundColor: { sm: grey[700] } },
      }}
      variant="contained"
      endIcon={<KeyboardArrowDownIcon />}
    >
      ACTIONS
    </Button>
  );
};

interface InvoicePartTabProps {
invoiceDetail:InvoiceDetails;
handleLinesSubmit:boolean;
setHandleLinesSubmit:any;
}

enum ActionTypeEnum {
  EDIT_PART = "EDIT_PART",
  DELETE = "DELETE",
  DEFAULT = "",
}

function formatValue(value: number | string, digits: number): string {
  return Number(value).toFixed(digits);
}


const InvoicePartTab = (props:InvoicePartTabProps)=>{
  const {handleLinesSubmit,invoiceDetail,setHandleLinesSubmit} = props
  
  const [rowSelected,setRowSelected] = useState<InvoiceLines[]>([])
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const [cellSelectionModel, setCellSelectionModel] = useState<GridCellSelectionModel>();
  const [invoiceLines,setInvoiceLines] = useState<InvoiceLines[]>([])
  const {enqueueSnackbar} = useSnackbar()
  const location = useLocation()
  const [action,setAction] = useState(ActionTypeEnum.DEFAULT)
  const [tabDialogOpen,setTabDialogOpen] =useState(false)
  const [openInvoice,setOpenInvoice] = useState(false);
  const globalConfig = useGlobalConfig();


  const columns: GridColDef[] = useMemo(()=>[
         {
          headerName:"Part Number",
          field:"part_number",
          flex:0.5
         },
        {
            headerName: "Description",
            field: "line_description",
            flex: 1,
        },
         {
            headerName: "Job ID",
            field: "job_id",
            flex: .5,
        }, 
         {
            headerName: "Cost",
            field: "price",
            flex: .5,
            valueFormatter: (params,row) => {
              return formatValue(row?.price, globalConfig?.cost_rounding ?? 4)
            },   
            headerAlign: "right",
            align: "right",
        }, 
        {
            headerName: "Unit Price",
            field: "buy",
            flex: .5,
            editable:true,
            valueFormatter: (params,row) => {
              return formatValue(row?.buy, globalConfig?.value_rounding ?? 4)
            },   
            headerAlign: "right",
            align: "right",
        },
        {
            headerName: "Discount %",
            field: "discount",
            flex: .5,
            editable:true,
            headerAlign: "right",
            align: "right",
            valueFormatter: (params,row) => {
              return formatValue(row?.discount, globalConfig?.quantity_rounding ?? 2)
            }, 
        },
         {
            headerName: "Sell Price",
            field: "sell",
            flex: .5,
            editable:true,
            valueFormatter: (params,row) => {
              return formatValue(row?.sell, globalConfig?.value_rounding ?? 4)
            },
            headerAlign: "right",
            align: "right",
        },
        {
            headerName: "Quantity",
            field: "quantity",
            flex: .5,
            editable:true,
            valueFormatter: (params,row) => {
              return formatValue(row?.quantity, globalConfig?.quantity_rounding ?? 0)
            },   
            headerAlign: "right",
            align: "right",
        },
        {
            headerName: "Unit",
            field: "uom",
            flex: .5,
        },
      
        {
            headerName: "Total",
            field: "total",
            flex: .5,
            valueFormatter: (params,row) => {
              return (((row?.sell ?? 0 )* (row?.quantity ?? 0)).toFixed(4));
            },   
            headerAlign: "right",
            align: "right",
        },
        {
          headerName:"Notes",
          field:"notes",
          flex:0.5,
          renderCell:(cell)=><>
          <LightTooltip title={`S/N:${cell.row.serail_number} ,Batch:${cell.row.batch} ,Notes:${cell.row.note} ,  Priority:${cell.row.priority}`}>
            <IconButton>
              <DescriptionOutlinedIcon />
            </IconButton>
          </LightTooltip>
          </>
          
        }
  ],[globalConfig])

  const actions = useMemo(()=>{
    const defaultAction:ActionMenuItem[] = [
      {
        name:"Edit Part",
        action:['Edit_Part','EDIT_PART'],
        onClick:()=>{
          if(rowSelected.length === 0){
            enqueueSnackbar("Select row to conitnue",{
              variant:"warning"
            })
            return;
          }
          else if(rowSelected.length >1)
          {
            enqueueSnackbar("Select single row to continue",{
              variant:"warning"
            })
            return ;
          }
          setAction(ActionTypeEnum.EDIT_PART)
        }
      },
      {
        name:"Remove",
        action:['Remove','REMOVE'],
        onClick:()=>{
          setTabDialogOpen(true)
        }
      }
    ]
    return defaultAction
  },[enqueueSnackbar,rowSelected])

  const getLines = useCallback( async()=>{
      axios.get(
        `/v6/invoice/lines/${invoiceDetail.id}`, {
          id:`get-lines-${invoiceDetail.id}`,
      }
      ).then((resp:AxiosResponse<InvoiceLines[]>)=>{
          setInvoiceLines(resp.data as InvoiceLines[])
      axios.storage.remove(`get-lines-${invoiceDetail.id}`)
      }).catch((error)=>{
             enqueueSnackbar("Error fetching invoice lines", {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        });
      })
  
},[enqueueSnackbar,invoiceDetail.id])

useEffect(()=>{
  let active = true;
  if (active){
    getLines()
  }
  return () => {
      active = false;
  };
},[getLines])
  const deleteInvoice = async()=>{
    const InvoiceLineIDs = rowSelected.map((row)=>{
      return row.id
    })
    if(InvoiceLineIDs.length===0){
      return;
    }
    for (const id of InvoiceLineIDs) {
    await  axios.delete(`/v6/invoice/line/${id}`).then(() => {
        enqueueSnackbar(`Invoice deleted ${id}`, {
          variant: "success",
        });
        
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(`Error deleting Invoice`, {
          variant: "error",
        });
      });
    }
    setRowSelected([])
    getLines()
   
  }


  useEffect(()=>{
    if(handleLinesSubmit)
    {
      //@ts-ignore
      invoiceDetail.lines = invoiceLines
      //@ts-ignore
      invoiceDetail['locked_by'] = invoiceDetail.locked_by === '' ? 0:Number(invoiceDetail.locked_by)
      const msg = "updated";
      let uri = location.pathname;
      axios({
        method: "put",
        url: `/v6/invoice/${invoiceDetail.id}`,
        data: invoiceDetail,
    }).then((resp:AxiosResponse<InvoiceDetails>)=>{
     setInvoiceLines(resp.data.lines as InvoiceLines[])
     setHandleLinesSubmit(false)
     axios.storage.remove(`get-invoice-${invoiceDetail.id}`)
     enqueueSnackbar(
      <CustomSnackbarContent
        message={`Successfully ${msg} order`}
        uri={uri}
      />,
      {
        variant: "success",
      }
    );
    }).catch((error)=>{
      enqueueSnackbar(`Error saving invoice`, {
        variant: "error",
      });
      console.log(error,"error")
    })
    }
  },[handleLinesSubmit,invoiceDetail,enqueueSnackbar,invoiceLines,location.pathname,setHandleLinesSubmit])

  const rowUpdate =  (values)=>{
    let counter = 0
    const updatedRows = invoiceLines &&  invoiceLines.map((row,index) =>{

        if(cellSelectionModel && row.id in cellSelectionModel)
        {
            const  key =  Object.entries(cellSelectionModel[row.id]).map((entry:[string,unknown])=>{
            return entry[0] as string
            })
           const updatedRow = {...row}

          if(
            values.length === 1
          )
          {
            for(const field in key)
            {
             updatedRow[key[field]]= key[field] in ['sell','quantity','price','cost']?Number(values[0][0]):values[0][0]
            }
          }
          else{
            for(const field in key)
            {
                
             updatedRow[key[field]]= key[field] in ['sell','quantity','price','cost'] ?Number(values[counter][field]):values[counter][field]
            }
          }
          counter+= 1;
            return updatedRow
        }
        else{
            return row
        }
      
    }
    );
   
    setInvoiceLines(updatedRows)
}

const handleCellEditStop = (params:GridCellParams, event) => {
  const {field} = params
  const updatedRows =invoiceLines &&  invoiceLines?.map((row) => {
    if (row.id === params.row.id) {
      const updaterow = {...row}
      const value = event['target']['value']
      
      updaterow[field] =(field === 'sell' || field === 'quantity') ? Number(value):event['target']['value']
      return updaterow
    }
    return row;
  });
  setInvoiceLines(updatedRows);
};


function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton/>
      <GridToolbarExport {...props} />
    </GridToolbarContainer>
  );
}

  return (
    <>
    <Grid container rowSpacing={1}>
      <Grid item xs={12}>
      <CreateFab onClick={()=>{
        setOpenInvoice(true)
      }} key={1} />
        <ActionMenu
          key={2}
          actions={actions}
          
        />
      </Grid>
      <Grid item xs={12}>
        <DataGridPremium
        columns={columns}
        rows={invoiceLines}
        autoHeight
        className='invoice-datagrid'
        checkboxSelection
        disableRowSelectionOnClick
        cellSelection = {true}
        onCellEditStop={handleCellEditStop}
        slots={{ toolbar: CustomToolbar ,
          filterPanel:CustomFilterPanelPremium
          }}
        onRowSelectionModelChange={(newRowSelectionModel)=>{
          setRowSelectionModel(newRowSelectionModel)
            console.log(newRowSelectionModel)
            const selectedRows = invoiceLines.filter((row) =>
            newRowSelectionModel.includes(row.id)
          );
          setRowSelected(selectedRows);
        }}
        rowSelectionModel={rowSelectionModel}
        onCellSelectionModelChange={(cellSelectionModel: GridCellSelectionModel,details:GridCallbackDetails) => {
          setCellSelectionModel(cellSelectionModel)
   
      }}
      cellSelectionModel={cellSelectionModel}
      onClipboardPasteStart={(values) => {
        rowUpdate(values.data)
      }}

        />
        
      </Grid>
    </Grid>

      {
        action === ActionTypeEnum.EDIT_PART && rowSelected.length <=1 ?
        (
          <>
          <InvoiceEditPart   invoicePart={rowSelected[0]} open = { action === ActionTypeEnum.EDIT_PART} onClose={()=>{
            setAction(ActionTypeEnum.DEFAULT)
            // axios.storage.remove(`get-invoice-${invoiceDetail.id}`)
            // setInvoiceUpdate(!invoiceLineUpdate)
            setRowSelected([])
            getLines()
            // setTabValue(0)
          }} />
          </>
        ):null
      }
      <AlertDialog
        title="Delete this record?"
        open={tabDialogOpen}
        handleClose={() => {
          setTabDialogOpen(false);
        }}
      >
        <Button
          onClick={() => {
            setTabDialogOpen(false);
          }}
          color="primary"
        >
          No
        </Button>
        <Button
          onClick={() => {
            setTabDialogOpen(false);
            deleteInvoice()
          }}
          color="primary"
          autoFocus
        >
          Yes
        </Button>
      </AlertDialog>
      <InvoicePart
      invoiceDetail={invoiceDetail}
      open={openInvoice}
      onClose={()=>{
        setOpenInvoice(false)
        getLines();
      }}
      />
    </>
  )
}

export default InvoiceDetail