import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  Box,
  Tab,
  Grid,
  Button,
  useTheme,
  MenuItem,
  Skeleton,
  Typography,
  GridSpacing,
  useMediaQuery,
  LinearProgress,
} from "@mui/material";

import * as Yup from "yup";
import queryString from "query-string";
import { useSnackbar } from "notistack";
import { format, parse } from "date-fns";
import { AxiosResponse, Method } from "axios";

import { DatePicker } from "formik-mui-x-date-pickers";
import { CheckboxWithLabel, Select, TextField } from "formik-mui";
import { Field, Form, Formik, FormikValues, useFormikContext } from "formik";

import { axios, useQuery } from "../../api";
import Header from "../../components/Header";
import Content from "../../components/Content";
import { SaveFab } from "../../components/Buttons";
import { FormDivider } from "../../components/Form";
import AlertDialog from "../../components/AlertDialog";
import { LoadingView } from "../../components/Loading";
import { TabBar, TabPanel } from "../../components/Tabs";
import CustomSnackbarContent from "../../components/CustomSnackbarContent";

import Items from "./tabs/Items";
import { getPartsGroups } from "../../parts/groups";
import { getAircraftTypes } from "../../parts/actypes";
import { getCurrencies } from "../../currency/currency";
import { handleAPIErrors } from "../../utils/FormikErrors";
import { getAircraftConfiguration } from "../../aircraft/config";
import { CompanyAutocomplete } from "../../companies/FormikCompanyAutocomplete";

import { useGlobalConfig } from "../../hooks/useGlobalConfig";

import type {
  AeronetDetailViewProps,
  AeronetV6Response,
  Currency,
} from "../../types";
import type { Group, Limitation } from "../types";
import type { IPartDetail } from "../../parts/types";
import type { AircraftType } from "../../aircraft/types";

enum LeadTimeCodeEnum {
  NONE = 0,
  EX_STOCK = 1,
  DAYS_1_2 = 2,
  DAYS_3_5 = 3,
  WEEKS_1_2 = 4,
  WEEKS_2_4 = 5,
  MONTHS_1_2 = 6,
  MONTHS_2_3 = 7,
  MONTHS_3_6 = 8,
  MONTHS_6_9 = 9,
  MONTHS_9_12 = 10,
  MONTHS_12_plus = 11,
}

export const LIST_LEAD_TIME: { key: number; value: string }[] = [
  {
    key: LeadTimeCodeEnum.NONE,
    value: "None",
  },
  {
    key: LeadTimeCodeEnum.EX_STOCK,
    value: "Ex Stock",
  },
  {
    key: LeadTimeCodeEnum.DAYS_1_2,
    value: "1-2 days",
  },
  {
    key: LeadTimeCodeEnum.DAYS_3_5,
    value: "3-5 days",
  },
  {
    key: LeadTimeCodeEnum.WEEKS_1_2,
    value: "1-2 weeks",
  },
  {
    key: LeadTimeCodeEnum.WEEKS_2_4,
    value: "2-4 weeks",
  },
  {
    key: LeadTimeCodeEnum.MONTHS_1_2,
    value: "1-2 months",
  },
  {
    key: LeadTimeCodeEnum.MONTHS_2_3,
    value: "2-3 months",
  },
  {
    key: LeadTimeCodeEnum.MONTHS_3_6,
    value: "3-6 months",
  },
  {
    key: LeadTimeCodeEnum.MONTHS_6_9,
    value: "6-9 months",
  },
  {
    key: LeadTimeCodeEnum.MONTHS_9_12,
    value: "9-12 months",
  },
  {
    key: LeadTimeCodeEnum.MONTHS_12_plus,
    value: "12 months +",
  },
];

/**
 * Format a value to a fixed number of decimal places
 * @param value
 * @param digits
 * @returns number
 */
function formatValue(value: number | string, digits: number): string {
  return Number(value).toFixed(digits);
}

interface InventoryDetailProps extends AeronetDetailViewProps {
  canSavePart: boolean;
  config: { [key: string]: any };
}

const InventoryDetail = (props: InventoryDetailProps) => {
  const { canSavePart, config, originalUrl } = props;
  let { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));
  let gridSpacing: GridSpacing = isSmall ? 2 : 6;
  let isRendered = useRef(false);
  const [part, setPart] = useState<Partial<IPartDetail>>();
  const [title, setTitle] = useState("");
  const formRef = useRef<FormikValues>();
  const [uom, setUOM] = useState<string[]>([]);
  const [statuses, setStatuses] = useState<{ [key: string]: string }>({});
  const [aircraftTypes, setAircraftTypes] = useState<AircraftType[]>();
  const [aircraftTypeEnabled, setAircraftTypeEnabled] =
    useState<Boolean>(false);
  let navigate = useNavigate();
  const [aircraftConfigurationTypes, setAircraftConfigurationTypes] = useState<
    AircraftType[] | null
  >(null);
  const globalConfig = useGlobalConfig();

  // Tab states
  const [tabValue, setTabValue] = useState(0);
  const [navigateAfterSave, setNavigateAfterSave] = useState<boolean>(true);
  const [tabDialogOpen, setTabDialogOpen] = useState(false);

  const [aircraftConfiguration] = useQuery(getAircraftConfiguration);
  const [aircraftTypeTypes] = useQuery(getAircraftTypes);

  /** Set aircraftConfigurationTypes from aircraft config. */
  useMemo(() => {
    if (aircraftConfiguration) {
      const aircraftTypes: AircraftType[] = aircraftConfiguration.data.map(
        ({ id, model }) => ({
          id,
          name: model,
        })
      );
      setAircraftConfigurationTypes(aircraftTypes);
    }
  }, [aircraftConfiguration]);

  /** Set UOM and status from config. */
  useMemo(() => {
    if (config) {
      setUOM(config.units_list);
      setStatuses(config.main_status);
    }
  }, [config]);

  /** Sets the title to "Part: <id>" or "New part". */
  useEffect(() => {
    if (id) {
      if (
        part &&
        Object.prototype.hasOwnProperty.call(part, "id") &&
        part["id"]
      ) {
        if (part.description && part?.description.trim()) {
          setTitle(`${part.number} - ${part.description.trim()}`);
        } else {
          setTitle(`Part: ${part.number}`);
        }
      }
    } else {
      setTitle("New Part");
    }
  }, [id, part]);

  /** Fetches the part by id or sets up a new part. */
  useEffect(() => {
    isRendered.current = true;
    if (id) {
      axios
        .get(`/v6/parts/${id}`, { id: `get-part-${id}` })
        .then((resp) => {
          if (isRendered.current) {
            let part = {} as IPartDetail;
            for (var value in resp.data) {
              part[value] = resp.data[value] === null ? "" : resp.data[value];
            }

            if (part.list_cost_current_to === "") {
              part.list_cost_current_to = null;
            } else {
              part.list_cost_current_to = parse(
                part.list_cost_current_to as string,
                "dd/MM/yyyy",
                new Date()
              );
            }

            if (part.list_price_current_to === "") {
              part.list_price_current_to = null;
            } else {
              part.list_price_current_to = parse(
                part.list_price_current_to as string,
                "dd/MM/yyyy",
                new Date()
              );
            }

            // format numbers
            part.list_cost = formatValue(part.list_cost, globalConfig?.value_rounding ?? 4);
            part.list_price = formatValue(part.list_price, globalConfig?.value_rounding ?? 4);

            setPart(part);
          }
          return null;
        })
        .catch((_error) => {
          enqueueSnackbar(`Error fetching part ${id}`, {
            variant: "error",
          });
          navigate("..");
        });
    } else {
      const newPart: Partial<IPartDetail> = {
        aircraft_type_id: "",
        alt_part_1: "",
        alt_part_2: "",
        description: "",
        flag: false,
        group: { id: 0 },
        inspection_life_applicable: false,
        is_consumable: false,
        is_fixed_wing_part: false,
        is_helicopter_part: false,
        is_rotable: false,
        list_cost_currency: "",
        list_cost_current_to: null,
        list_cost: "0.00",
        list_price_currency: "",
        list_price_current_to: null,
        list_price: "0.00",
        notes: "",
        number: "",
        part_superseded_by: "",
        serial_number_required: false,
        shelf_life_applicable: false,
        status: "ACT",
        supplier: {
          id: 0,
          name: "",
        },
        uom: "EACH",
      };
      setPart(newPart);
    }
    return () => {
      isRendered.current = false;
    };
  }, [id, enqueueSnackbar, globalConfig, navigate]);

  /** Enables the aircraft select */
  useEffect(() => {
    isRendered.current = true;

    async function getLimitations() {
      try {
        const resp: AxiosResponse<AeronetV6Response<Limitation>> =
          await axios.get("/v6/parts/limitations", {
            params: {
              filter: [
                JSON.stringify({ field: "part_id", value: id }),
                JSON.stringify({ field: "active", value: true }),
              ],
            },
            paramsSerializer: (params) => queryString.stringify(params),
          });
        if (isRendered.current) {
          // enable if there are no limitations
          if (resp.data.total === 0) {
            console.debug("no limitations - enabling aircraft select");
            setAircraftTypeEnabled(true);
          } else {
            console.debug(
              "limitations apply - leaving aircraft select disabled"
            );
          }
        }
      } catch (error) {}
    }

    if (id) {
      console.debug(`existing part - id is ${id}`);
      if (config) {
        console.debug("config is set");
        if (config?.aircraft_type_restriction) {
          console.debug("aircraft_type_restriction is true");
          if (part) {
            if (part.aircraft_type_id && part.aircraft_type_id !== "-") {
              console.debug("checking limitations");
              getLimitations();
            } else {
              console.debug("actype not set - enabling aircraft select");
              // enable if we have an existing part with no actype set
              setAircraftTypeEnabled(true);
            }
          }
        } else {
          console.debug("aircraft_type_restriction false or not set");
          setAircraftTypeEnabled(true);
        }
      }
    } else {
      console.debug("new part - enabling aircraft select");
      setAircraftTypeEnabled(true);
    }

    return () => {
      isRendered.current = false;
    };
  }, [id, config, part, setAircraftTypeEnabled]);

  /** Populates the aircraft select from either aircraft configuration or type module AC Types */
  useEffect(() => {
    if (config) {
      if (config?.aircraft_type_restriction) {
        if (aircraftConfigurationTypes) {
          console.debug("setting aircraftTypes to aircraftConfigurationTypes");
          setAircraftTypes(aircraftConfigurationTypes);
        }
      } else {
        if (aircraftTypeTypes) {
          console.debug("setting aircraftTypes to aircraftTypeTypes");
          setAircraftTypes(aircraftTypeTypes.data as AircraftType[]);
        }
      }
    }
  }, [aircraftConfigurationTypes, aircraftTypeTypes, config]);

  const handleTabChange = (
    event: React.SyntheticEvent<{}> | null,
    newValue: any
  ) => {
    if (
      formRef.current &&
      ((formRef.current.dirty && !formRef.current.isSubmitting) ||
        !formRef.current.initialValues.id)
    ) {
      if (navigateAfterSave) {
        setNavigateAfterSave(false);
        setTabDialogOpen(true);
        return;
      }
    }
    setTabValue(newValue);
    setNavigateAfterSave(true);
  };

  const handleClose = () => {
    if (formRef.current) {
      formRef.current.submitForm().then(() => {
        if (
          formRef.current &&
          !formRef.current.isValidating &&
          formRef.current.isValid
        ) {
          handleTabChange(null, 1);
        }
      });
    }
  };

  const a11yProps = (index: any) => ({
    id: `part-tab-${index}`,
    "aria-controls": `part-tabpanel-${index}`,
  });

  const submitForm = () => {
    if (formRef.current) {
      formRef.current.submitForm().then(() => {
        if (
          formRef.current &&
          !formRef.current.isValidating &&
          formRef.current.isValid
        ) {
          navigate(-1)
        }
      });
    }else {
      if (tabValue > 0) {
        navigate(-1);
      }
    }
  };  
  const saveForm = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    } else {
      if (tabValue > 0) {
        navigate(-1);
      }
    }
  };

  return (
    <>
      {canSavePart ? (
        <Header actions={[<SaveFab onClick2={saveForm} onClick={submitForm} key={1} />]}>
          {title}
        </Header>
      ) : (
        <Header actions={[]}>{title}</Header>
      )}
      <TabBar value={tabValue} onChange={handleTabChange}>
        <Tab disableRipple label="General" {...a11yProps(0)} />
        <Tab disableRipple label="Items" {...a11yProps(1)} />
      </TabBar>
      <Content>
        {part ? (
          <>
            <TabPanel value={tabValue} index={0} data-testid="inventory-form">
              <InventoryForm
                part={part}
                uom={uom}
                statuses={statuses}
                aircraftTypes={aircraftTypes}
                aircraftTypeEnabled={aircraftTypeEnabled}
                gridSpacing={gridSpacing}
                formRef={formRef}
                navigateAfterSave={navigateAfterSave}
                setPart={setPart}
                setTabValue={setTabValue}
                setNavigateAfterSave={setNavigateAfterSave}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={1} data-testid="part-items">
              <Items config={config} originalUrl={originalUrl} part={part} />
            </TabPanel>
          </>
        ) : (
          <LoadingView />
        )}
        <AlertDialog
          title="Are you sure?"
          description="Part is not saved"
          open={tabDialogOpen}
          handleClose={() => {
            setTabDialogOpen(false);
          }}
        >
          <Button
            onClick={() => {
              setTabDialogOpen(false);
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (formRef.current) {
                formRef.current.resetForm();
                setTabDialogOpen(false);
              }
            }}
            color="primary"
          >
            Reset
          </Button>
          <Button
            onClick={() => {
              setTabDialogOpen(false);
              handleClose();
            }}
            color="primary"
            autoFocus
          >
            Save
          </Button>
        </AlertDialog>
      </Content>
    </>
  );
};

const MarkupField = (props) => {
  const {
    // @ts-ignore
    values: { list_price, list_cost },
    setFieldValue,
  } = useFormikContext();
  const globalConfig = useGlobalConfig();
  const [markup, setMarkup] = useState<string>("0");

  useEffect(() => {
    if (list_price != null && list_price != null && globalConfig) {
      const markup = ((100 * (list_price - list_cost)) / list_cost).toFixed(
        globalConfig.quantity_rounding
      );
      if (!isNaN(Number(markup))) {
        setMarkup(markup);
      }
    }
  }, [list_price, list_cost, setFieldValue, globalConfig]);

  return <Typography {...props}>Markup: {markup}%</Typography>;
};

interface InventoryFormProps {
  aircraftTypeEnabled: Boolean;
  aircraftTypes?: AircraftType[];
  formRef: any;
  gridSpacing: GridSpacing;
  part: Partial<IPartDetail>;
  statuses: { [key: string]: string };
  uom: string[];
  navigateAfterSave: Boolean;
  setPart: any;
  setTabValue: any;
  setNavigateAfterSave: any;
}

const InventorySchema = Yup.object().shape({
  number: Yup.string().max(50, "Max 50 characters").required("Required"),
  description: Yup.string().max(55, "Max 55 characters").required("Required"),
});

const InventoryForm = (props: InventoryFormProps) => {
  const {
    aircraftTypeEnabled,
    aircraftTypes,
    formRef,
    gridSpacing,
    part,
    statuses,
    uom,
    navigateAfterSave,
    setPart,
    setTabValue,
    setNavigateAfterSave,
  } = props;
  const { enqueueSnackbar } = useSnackbar();

  let location = useLocation();
  const [currency] = useQuery(getCurrencies);
  const [group] = useQuery(getPartsGroups);
  const globalConfig = useGlobalConfig();

  return (
    <Formik
      enableReinitialize={true}
      initialValues={part}
      innerRef={formRef}
      validationSchema={InventorySchema}
      onSubmit={async (values, { resetForm, setErrors }) => {
        let method: Method;
        let url: string;
        let msg: string;
        let partData = values;

        if (Object.prototype.hasOwnProperty.call(part, "id") && part.id) {
          method = "patch";
          url = `/v6/parts/${part.id}`;
          msg = "updated";

          partData = Object.entries(values).reduce((acc, [key, value]) => {
            const hasChanged = part[key] !== value;

            if (hasChanged) {
              acc[key] = value;
            }

            return acc;
          }, {} as Partial<IPartDetail>);
        } else {
          method = "post";
          url = "/v6/parts";
          msg = "created";
        }

        if (!partData.group?.id) {
          partData.group = {
            id: 0,
          };
        }
        console.log(partData, "partData");
        if (
          Object.prototype.hasOwnProperty.call(partData, "list_cost_current_to")
        ) {
          if (!partData.list_cost_current_to) {
            partData.list_cost_current_to = "";
          } else {
            partData.list_cost_current_to = format(
              partData.list_cost_current_to as Date,
              "dd/MM/yyyy"
            );
          }
        }
        if (
          Object.prototype.hasOwnProperty.call(
            partData,
            "list_price_current_to"
          )
        ) {
          if (!partData.list_price_current_to) {
            partData.list_price_current_to = "";
          } else {
            partData.list_price_current_to = format(
              partData.list_price_current_to as Date,
              "dd/MM/yyyy"
            );
          }
        }

        return axios({
          method: method,
          url: url,
          data: partData,
          cache: {
            update: {
              [`get-part-${part.id}`]: "delete",
            },
          },
        })
          .then((resp) => {
            if (navigateAfterSave) {
              let uri = location.pathname;
              if (msg === "created") {
                uri = "/inventory/" + resp.data.id;
              }
              // navigate(-1);
              enqueueSnackbar(
                <CustomSnackbarContent
                  message={`Successfully ${msg} part`}
                  uri={uri}
                />,
                {
                  variant: "success",
                }
              );
            } else {
              enqueueSnackbar("Saved", {
                variant: "success",
              });

              if (resp?.data?.id) {
                setTabValue(1);
                setNavigateAfterSave(true);
              }
              setPart(resp.data);
              resetForm();
            }
          })
          .catch((error) => {
            handleAPIErrors(error, setErrors);
            enqueueSnackbar("Error saving part", {
              variant: "error",
            });
          });
      }}
    >
      {({
        submitForm,
        isSubmitting,
        setFieldValue,
        values,
        handleBlur,
        handleChange,
      }) => (
        <Form className='mui-form'>
          <Grid container spacing={gridSpacing}>
            <FormDivider>General Details</FormDivider>
            <Grid item xs={12} md={6} lg={4}>
              <Field
                autoFocus
                required
                fullWidth
                component={TextField}
                type="text"
                label="Part Number"
                name="number"
              />
              <br />
              <Field
                required
                fullWidth
                component={TextField}
                type="text"
                label="Description"
                name="description"
              />
              <br />
              {/* List Cost and List Price */}
              {currency ? (
                <>
                  <Box width={1} display="flex" alignItems="center">
                    <Field
                      component={Select}
                      name="list_cost_currency"
                      id="list_cost_currency"
                      label="Currency"
                      sx={{ marginRight: 1 }}
                      formControl={{
                        fullWidth: false,
                        sx: { minWidth: 115 },
                      }}
                      autoWidth
                    >
                      <MenuItem value="" key={0} disabled>
                        Currency
                      </MenuItem>
                      {currency.data.map((currency: Currency, index) => (
                        <MenuItem
                          value={currency.currency_short}
                          key={index + 1}
                        >
                          {currency.currency_short}
                        </MenuItem>
                      ))}
                    </Field>
                    {globalConfig ? (
                      <Field
                        component={TextField}
                        type="text"
                        label="List Cost"
                        name="list_cost"
                        inputProps={{ inputMode: "decimal", min: 0 }}
                        onBlur={(event) => {
                          const formatted = formatValue(values.list_cost!, globalConfig?.value_rounding ?? 4);
                          setFieldValue("list_cost", formatted);
                          handleBlur(event);
                        }}
                      />
                    ) : (
                      <Skeleton animation="wave" sx={{ fontSize: "2rem" }} />
                    )}
                    <Box sx={{ minWidth: 60, textAlign: "center" }}>
                      <Typography noWrap={true} component="span">
                        &nbsp;as at:&nbsp;
                      </Typography>
                    </Box>
                    {globalConfig ? (
                      <Field
                        component={DatePicker}
                        name="list_cost_current_to"
                        inputFormat={globalConfig?.date_format}
                        clearable={true}
                      />
                    ) : (
                      <Skeleton animation="wave" sx={{ fontSize: "2rem" }} />
                    )}
                  </Box>
                  <Box
                    width={1}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Field
                      component={Select}
                      name="list_price_currency"
                      id="list_price_currency"
                      label="Currency"
                      sx={{ marginRight: 1 }}
                      formControl={{
                        fullWidth: false,
                        sx: { minWidth: 115 },
                      }}
                      autoWidth
                    >
                      <MenuItem value="" key={0} disabled>
                        Currency
                      </MenuItem>
                      {currency.data.map((currency: Currency, index) => (
                        <MenuItem
                          value={currency.currency_short}
                          key={index + 1}
                        >
                          {currency.currency_short}
                        </MenuItem>
                      ))}
                    </Field>
                    {globalConfig ? (
                      <Field
                        component={TextField}
                        type="text"
                        label="List Price"
                        name="list_price"
                        inputProps={{ inputMode: "decimal", min: 0 }}
                        onBlur={(event) => {
                          const formatted = formatValue(values.list_price!, globalConfig?.value_rounding ?? 4);
                          setFieldValue("list_price", formatted);
                          handleBlur(event);
                        }}
                      />
                    ) : (
                      <Skeleton animation="wave" sx={{ fontSize: "2rem" }} />
                    )}
                    <Box sx={{ minWidth: 60, textAlign: "center" }}>
                      <Typography noWrap={true} component="span">
                        &nbsp;as at:&nbsp;
                      </Typography>
                    </Box>
                    {globalConfig ? (
                      <Field
                        component={DatePicker}
                        name="list_price_current_to"
                        inputFormat={globalConfig?.date_format}
                        clearable={true}
                      />
                    ) : (
                      <Skeleton animation="wave" sx={{ fontSize: "2rem" }} />
                    )}
                  </Box>
                </>
              ) : (
                <Skeleton animation="wave" sx={{ fontSize: "2rem" }} />
              )}
              <Box width={1} style={{ display: "flex", alignItems: "center" }}>
                {uom.length ? (
                  <Field
                    component={Select}
                    name="uom"
                    id="uom"
                    label="UOM"
                    sx={{ flexGrow: 1 }}
                    formControl={{
                      fullWidth: false,
                      sx: { minWidth: 275 },
                    }}
                  >
                    <MenuItem value="" key={0} disabled>
                      UOM
                    </MenuItem>
                    {uom.map((unit: string, index) => (
                      <MenuItem value={unit} key={index + 1}>
                        {unit}
                      </MenuItem>
                    ))}
                  </Field>
                ) : (
                  <></>
                )}
                <Box width="100%">
                  <MarkupField name="markup" sx={{ textAlign: "center" }} />
                </Box>
              </Box>
              <Field
                component={CompanyAutocomplete}
                id="supplier"
                name="supplier"
                companyType="supplier"
              />
              {/* <Field
                component={Select}
                name="lead_time"
                id="lead_time"
                label="Lead Time - Days"
              >
                {LIST_LEAD_TIME.map((item) => (
                  <MenuItem value={item.key} key={item.key}>
                    {item.value}
                  </MenuItem>
                ))}
              </Field> */}
              <Field
                fullWidth
                component={TextField}
                type="text"
                label="Lead Time - Days"
                name="lead_time"
              />
              {/* <Field
                fullWidth
                component={TextField}
                type="text"
                label="Lead Time - Days"
                name="lead_time_text"
              /> */}
              {group ? (
                <Field
                  autoWidth
                  component={Select}
                  name="group.id"
                  id="group"
                  label="Group"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {group.data.map((group: Group, index) => (
                    <MenuItem value={group.id} key={group.id}>
                      {group.name}
                    </MenuItem>
                  ))}
                </Field>
              ) : (
                <Skeleton animation="wave" sx={{ fontSize: "2rem" }} />
              )}
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Field
                component={Select}
                name="status"
                id="status"
                label="Status"
              >
                <MenuItem value="" key={0} disabled>
                  Status
                </MenuItem>
                {Object.entries(statuses).map(([key, value]) => (
                  <MenuItem value={key} key={key}>
                    {value}
                  </MenuItem>
                ))}
              </Field>
              <br />
              <Field
                fullWidth
                component={TextField}
                type="text"
                label="Part Superseded By"
                name="part_superseded_by"
              />
              <br />
              <Field
                fullWidth
                component={TextField}
                type="text"
                label="Alternative Part Number #1"
                name="alt_part_1"
              />
              <br />
              <Field
                fullWidth
                component={TextField}
                type="text"
                label="Alternative Part Number #2"
                name="alt_part_2"
              />
              <br />
              {aircraftTypes ? (
                <Field
                  component={Select}
                  name="aircraft_type_id"
                  id="aircraft_type_id"
                  label="Aircraft Type"
                  disabled={!aircraftTypeEnabled}
                  title={
                    aircraftTypeEnabled
                      ? ""
                      : "Editing disabled as limitations exist"
                  }
                >
                  <MenuItem value="" key={0}>
                    Select
                  </MenuItem>
                  {aircraftTypes.map((aircraftType: AircraftType, index) => (
                    <MenuItem value={aircraftType.id} key={aircraftType.id}>
                      {aircraftType.name}
                    </MenuItem>
                  ))}
                </Field>
              ) : (
                <Skeleton animation="wave" sx={{ fontSize: "2rem" }} />
              )}
              <br />
              <Field
                fullWidth
                multiline
                rows={6}
                component={TextField}
                type="text"
                label="Notes"
                name="notes"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <>
                <Field
                  component={CheckboxWithLabel}
                  name="serial_number_required"
                  type="checkbox"
                  color="primary"
                  Label={{
                    label: "Serial Number Required",
                    sx: { px: 1, pb: 0.5 },
                  }}
                />
                <br />
                <Field
                  component={CheckboxWithLabel}
                  name="shelf_life_applicable"
                  type="checkbox"
                  color="primary"
                  Label={{
                    label: "Shelf Life Applicable",
                    sx: { px: 1, py: 0.5 },
                  }}
                />
                <br />
                <Field
                  component={CheckboxWithLabel}
                  name="inspection_life_applicable"
                  type="checkbox"
                  color="primary"
                  Label={{
                    label: "Inspection Life Applicable",
                    sx: { px: 1, py: 0.5 },
                  }}
                />
                <br />
                <Field
                  component={CheckboxWithLabel}
                  name="is_consumable"
                  type="checkbox"
                  color="primary"
                  Label={{
                    label: "Consumable",
                    sx: { px: 1, py: 0.5 },
                  }}
                />
                <br />
                <Field
                  component={CheckboxWithLabel}
                  name="is_rotable"
                  type="checkbox"
                  color="primary"
                  Label={{
                    label: "Rotable",
                    sx: { px: 1, py: 0.5 },
                  }}
                />
                <br />
                <Field
                  component={CheckboxWithLabel}
                  name="is_fixed_wing_part"
                  type="checkbox"
                  color="primary"
                  Label={{
                    label: "Fixed Wing Part",
                    sx: { px: 1, py: 0.5 },
                  }}
                />
                <br />
                <Field
                  component={CheckboxWithLabel}
                  name="is_helicopter_part"
                  type="checkbox"
                  color="primary"
                  Label={{
                    label: "Helicopter Part",
                    sx: { px: 1, py: 0.5 },
                  }}
                />
                <br />
                <Field
                  component={CheckboxWithLabel}
                  name="flag"
                  type="checkbox"
                  color="primary"
                  Label={{
                    label: "Flag",
                    sx: { px: 1, py: 0.5 },
                  }}
                />
              </>
            </Grid>
          </Grid>
          {isSubmitting && <LinearProgress />}
        </Form>
      )}
    </Formik>
  );
};

export default InventoryDetail;
