import { useEffect, useMemo, useState } from "react";

import SettingsIcon from "@mui/icons-material/Settings";
import {
  Grid,
  TextField as MUITextField,
  Skeleton,
  Typography,
} from "@mui/material";
import MuiAutocomplete from "@mui/material/Autocomplete";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { AxiosResponse } from "axios";
import {
  AutocompleteProps,
  AutocompleteRenderInputParams,
  fieldToAutocomplete,
  fieldToTextField,
} from "formik-mui";
import throttle from "lodash/throttle";

import { axios } from "../api";
import { useGlobalConfig } from "../hooks/useGlobalConfig";

import type { AeronetV6ItemsResponse } from "../types";
import type { ISorPart } from "../sors/types";
import { useExchangeRates } from "../hooks/useExchangeRates";
import queryString from "query-string";

export function PartAutocomplete<
  // eslint-disable-next-line
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>(props: AutocompleteProps<ISorPart, Multiple, DisableClearable, FreeSolo>) {
  const { form, onChange } = props;
  const { setFieldValue } = form;
  const [parts, setParts] = useState<Array<any>>([]);
  const [inputValue, setInputValue] = useState("");
  const { name } = fieldToTextField(props as any);
  const globalConfig = useGlobalConfig();
  const exchangeRates = useExchangeRates();

  const fetchParts = useMemo(
    () =>
      throttle((value: string, callback: Function) => {
        let params: { [key: string]: any } = {
          page: 1,
          size: 20,
          sort: "id",
          filter: [],
        };
        if (value) {
          params["filter"].push(
            JSON.stringify({ field: "number", op: "ilike", value: value })
          );
        }
        if (value) {
          params["q"] = value;
        }
        axios
          .get("/v6/parts", {
            params: params,
            paramsSerializer: (params) => queryString.stringify(params),
            id: `get-parts`,
          })
          .then((resp: AxiosResponse<AeronetV6ItemsResponse<ISorPart>>) => {
            axios.storage.remove("get-parts");
            callback(resp.data.items);
          })
          .catch((_error) => {
            callback([]);
          });
      }, 200),
    []
  );

  useEffect(() => {
    let active = true;

    fetchParts(inputValue, (results: ReadonlyArray<ISorPart>) => {
      if (active) {
        let newParts: Array<string | ISorPart> = [];

        if (results) {
          newParts = [...newParts, ...results];
        }

        setParts(newParts);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, fetchParts]);

  const handleChange = (
    _event: React.SyntheticEvent<Element, Event>,
    newValue: any,
    reason,
    details
  ) => {
    if (onChange && exchangeRates) {
      const part = newValue;
      onChange(_event, newValue, reason, details);
      setFieldValue("part", part);
      setFieldValue("part_id", part?.id);
      setFieldValue("part_number", part?.number);
      setFieldValue("part_name", part?.name);
      if (typeof part === "string") {
        setFieldValue("part_id", part);
        setFieldValue("lines.0.part_id", part);
      } else {
        setFieldValue("part_id", part && part.id ? part.id : "");
        setFieldValue("lines.0.part_id", part && part.id ? part.id : "");
      }
      if (part && typeof part !== "string") {
        setFieldValue("buy", {
          name: `List Cost ${Number(part?.list_cost).toFixed(
            globalConfig?.cost_rounding ?? 2
          )} ${part?.list_cost_currency ?? ""}`,
          value: part?.list_cost.toString() || "",
        });
        setFieldValue("sell", {
          name: `List Price ${Number(part?.list_price).toFixed(
            globalConfig?.value_rounding ?? 2
          )} ${part?.list_price_currency ?? ""}`,
          value: part?.list_price.toString() || "",
        });
        if (part.list_cost_currency) {
          setFieldValue("currency_code", part.list_cost_currency);
          setFieldValue("exchange", exchangeRates[part.list_cost_currency]);
        }
      }
    }
  };

  return (
    <>
      {exchangeRates ? (
        <MuiAutocomplete
          {...fieldToAutocomplete(props)}
          fullWidth
          options={parts}
          autoComplete
          includeInputInList
          //@ts-ignore
          onChange={handleChange}
          onInputChange={(_event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          getOptionLabel={(option) =>
            typeof option === "string" ? option : option.number
          }
          isOptionEqualToValue={(option, value) =>
            option.number === value.number
          }
          renderInput={(params: AutocompleteRenderInputParams) => (
            <MUITextField
              {...params}
              label="Part Number"
              error={props.form.touched[name!] && !!props.form.errors[name!]}
              // @ts-ignore
              helperText={props.form.touched[name!] && props.form.errors[name!]}
              fullWidth
            />
          )}
          renderOption={(props, option, state) => {
            const matches = match(option.number, state.inputValue);
            const parts = parse(option.number, matches);

            return (
              <li {...props}>
                <Grid container alignItems="center">
                  <Grid item>
                    <SettingsIcon
                      sx={{
                        color: "text.secondary",
                        marginRight: 2,
                      }}
                    />
                  </Grid>
                  <Grid item xs>
                    {parts.map((part, index) => (
                      <span
                        key={index}
                        style={{ fontWeight: part.highlight ? 700 : 400 }}
                      >
                        {part.text}
                      </span>
                    ))}
                    <Typography variant="body2" color="textSecondary">
                      {option.description} ({option.id})
                    </Typography>
                  </Grid>
                </Grid>
              </li>
            );
          }}
        />
      ) : (
        <Skeleton animation="wave" sx={{ fontSize: "2rem" }} />
      )}
    </>
  );
}

PartAutocomplete.displayName = "FormikMaterialUIPartAutocomplete";
