import { useEffect, useMemo, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import BusinessIcon from "@mui/icons-material/Business";
import {
  Box,
  Grid,
  TextField as MUITextField,
  Typography,
} from "@mui/material";
import MuiAutocomplete from "@mui/material/Autocomplete";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { AxiosResponse } from "axios";
import {
  AutocompleteProps,
  fieldToAutocomplete,
  fieldToTextField,
} from "formik-mui";
import { throttle } from "lodash";
import queryString from "query-string";

import { axios } from "../api";
import { useHasPermission } from "../hooks/useHasPermission";
import { FormikAddAccount } from "./FormikAddAccount";

import type { AeronetV6ItemsResponse } from "../types";
import type { AccountType, CompanyV6 } from "./types";
import { useCurrentUser } from "../hooks/useCurrentUser";

export interface CompanyAutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
  companyType?: AccountType;
  label?: string;
  disableFilterName?: boolean;
  notHasAddNewCompanyPermission?: boolean;
  disable?:boolean;
}

export function CompanyAutocomplete<
  // eslint-disable-next-line
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>(
  props: CompanyAutocompleteProps<
    CompanyV6,
    Multiple,
    DisableClearable,
    FreeSolo
  >
) {
  let {
    label,
    companyType = "",
    disableFilterName = false,
    notHasAddNewCompanyPermission,
    disable,
    ...autocompleteProps
  } = props;
  if (!label) {
    label = companyType;
  }
  const [companies, setCompanies] = useState<CompanyV6[]>([]);
  const currentUser = useCurrentUser();
  const [inputValue, setInputValue] = useState("");
  const { name } = fieldToTextField(props as any);
  const { setFieldValue } = autocompleteProps.form;
  const hasEditPermission = useHasPermission("companies", "edit");
  let addLabel = companyType !== "" ? `Add ${companyType}` : "Add company";

  const fetchCompanies = useMemo(
    () =>
      throttle((value: string, callback: Function) => {
        let params: { [key: string]: any } = {
          page: 1,
          size: 20,
          sort: "-id",
          filter: [],
        };

        params.filter.push(
          JSON.stringify({
            field: "active",
            op: "==",
            value: true,
          })
        );

        if (companyType !== "") {
          params["filter"].push(
            JSON.stringify({
              field: "type",
              op: "ilike",
              value: "%" + companyType + "%",
            })
          );
        }

        if (!disableFilterName) {
          if (value) {
            params["filter"].push(
              JSON.stringify({
                model: "Company",
                field: "name",
                op: "ilike",
                value: "%" + value + "%",
              })
            );
            params.sort = "name";
          } else {
            params["filter"].push(
              JSON.stringify({
                model: "Company",
                field: "name",
                op: "!=",
                value: "",
              })
            );
          }
        }

        if (notHasAddNewCompanyPermission) {
          params["filter"].push(
            JSON.stringify({
              field: "user_company",
              op: "==",
              value: currentUser?.id || "",
            })
          );
        }
        axios
          .get("/v6/companies", {
            params: params,
            paramsSerializer: (params) => queryString.stringify(params),
          })
          .then((resp: AxiosResponse<AeronetV6ItemsResponse<CompanyV6>>) => {
            callback(resp.data.items);
          })
          .catch(() => {
            callback([]);
          });
      }, 200),
    [companyType, notHasAddNewCompanyPermission, disableFilterName, currentUser]
  );

  useEffect(() => {
    let active = true;

    fetchCompanies(inputValue, (results: ReadonlyArray<CompanyV6>) => {
      if (active) {
        let newCompanies: CompanyV6[] = [];

        if (results) {
          newCompanies = [...newCompanies, ...results];
        }

        if (hasEditPermission && !notHasAddNewCompanyPermission) {
          // @ts-ignore
          newCompanies.unshift(addLabel);
        }

        setCompanies(newCompanies);
      }
    });

    return () => {
      active = false;
    };
  }, [
    inputValue,
    fetchCompanies,
    addLabel,
    hasEditPermission,
    notHasAddNewCompanyPermission,
  ]);

  return (
    <>
      <MuiAutocomplete
        {...fieldToAutocomplete(autocompleteProps)}
        fullWidth
        options={companies}
        autoComplete
        includeInputInList
        disabled={disable || false}
        open = {disable ===true ? false: undefined}
        onChange={(_event: React.SyntheticEvent, value, _reason, _details?) => {
          if (value) {
            setFieldValue(name!, value);
          } else {
            setFieldValue(name!, "");
          }
          if (props.onChange) {
            props.onChange(_event, value, _reason, _details);
          }
        }}
        onInputChange={(_event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        getOptionLabel={(option) => {
          if (typeof option === "string") {
            return option;
          } else {
            if (option.currency) {
              return `${option.name} (${option.currency})`;
            }
            return option.name || "";
          }
        }}
        isOptionEqualToValue={(option, value) =>
          // @ts-ignore
          option.id === value?.id || value === ""
        }
        renderInput={(params) => (
          <MUITextField
            {...params}
            label={label}
            error={props.form.touched[name!] && !!props.form.errors[name!]}
            // @ts-ignore
            helperText={props.form.touched[name!] && props.form.errors[name!]}
            sx={{ textTransform: "capitalize" }}
          />
        )}
        renderOption={(props, option: CompanyV6, state) => {
          if (typeof option === "string") {
            return (
              <li {...props} key={-1}>
                <Grid container alignItems="center">
                  <Grid item>
                    <AddIcon
                      sx={{
                        color: "text.secondary",
                        marginRight: 2,
                      }}
                    />
                  </Grid>
                  <Grid item xs>
                    <span
                      style={{
                        fontWeight: 700,
                      }}
                    >
                      {option}
                    </span>
                  </Grid>
                </Grid>
              </li>
            );
          } else {
            const matches = match(option.name, state.inputValue, {
              insideWords: true,
            });
            const companies = parse(option.name, matches);

            return (
              <li {...props} key={option.id}>
                <Grid container alignItems="center">
                  <Grid item>
                    <BusinessIcon
                      sx={{
                        color: "text.secondary",
                        marginRight: 2,
                      }}
                    />
                  </Grid>
                  <Grid item xs>
                    {companies.map((company, index) => (
                      <span
                        key={index}
                        style={{
                          fontWeight: company.highlight ? 700 : 400,
                        }}
                      >
                        {company.text}
                      </span>
                    ))}
                    {option.currency && (
                      <Box component="span" sx={{ pl: 0.5 }}>
                        ({option.currency})
                      </Box>
                    )}

                    <Typography variant="body2" color="textSecondary">
                      {option.id}
                    </Typography>
                  </Grid>
                </Grid>
              </li>
            );
          }
        }}
      />
      {hasEditPermission && (!disable) &&
        typeof props.form.values[name!] === "string" &&
        props.form.values[name!].startsWith("Add") && (
          <FormikAddAccount
        
            setValue={(newAccount) => setFieldValue(name!, newAccount)}
            // @ts-ignore
            accountType={companyType || "supplier"}
          />
        )}
    </>
  );
}

CompanyAutocomplete.displayName = "FormikMaterialUCompanyV6Autocomplete";
