import React, { lazy, MutableRefObject, useCallback, useMemo, useState } from 'react'
import { AeronetViewProps } from '../types'
import { GridApi, GridColDef, GridFilterModel, GridSortItem, GridValueFormatterParams, ValueOptions } from '@mui/x-data-grid-pro'
import  AeronetDataGrid, { QueryResults }  from '../components/datagrid/DataGrid'
import { CacheRequestConfig } from 'axios-cache-interceptor'
import { useCurrentUser } from '../hooks/useCurrentUser'
import { AxiosResponse } from 'axios'
import { axios } from '../api'
import queryString from 'query-string'
import { useSnackbar } from 'notistack'
import { DueDateDeletePayload, DueDateList } from './type'
import { useConfig } from '../hooks/useConfig'
import { ActionMenuItem } from '../components/datagrid/ActionMenu'
import { useNavigate } from 'react-router-dom'
import DueDateToolbar from './DueDateToolbar'
import { Button } from '@mui/material'
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DueDatePreview from './DueDatePreview'
import AlertDialog from '../components/AlertDialog'
const DueDateAdvancedSearchForm = lazy(() => import("./AdvanceSearchForm"));
interface DueDateDataGridProps extends AeronetViewProps{
  canCreateDuedate:boolean
canDeleteDuedate:boolean

}

function addDays(dateStr, days) {
  const date = new Date(dateStr);
  const newDate = new Date(date.getTime() + days * 24 * 60 * 60 * 1000);
  return newDate.toISOString().split('T')[0];
}

function daysRemaining(dateStr) {
  const date = new Date(dateStr);
  const currentDate = new Date();
  const timeDiff = date.getTime() - currentDate.getTime();
  const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
  return daysDiff;
}


const DueDateDataGrid: React.FC<DueDateDataGridProps>  = (props) => {
    const {enqueueSnackbar} = useSnackbar();
    const navigate = useNavigate();
    const {canCreateDuedate} = props
    const dueDateConfig =useConfig("whiteboard")
    const [previewSelected, setPreviewSelected] = useState<number>();
    const [previewSelectedStatus, setPreviewSelectedStatus] =
      useState<any>();
    const [tabDialogOpen, setTabDialogOpen] = useState(false);
    const [deletePayload, setDeletePayload] = useState<DueDateDeletePayload | null>(
      null
    );
    
    const [selectedRows, setSelectedRows] = useState<DueDateList[]>([]);
    const currentUser = useCurrentUser();
    const statusOptions: ValueOptions[] | undefined = useMemo(() => {
      if (dueDateConfig && dueDateConfig.status_list) {
        const statuses: ValueOptions[] = [{ value: "", label: "Any" }];
        Object.keys(dueDateConfig?.status).forEach((key) => {
          statuses.push({
            value: key,
            label: dueDateConfig?.status[key],
          });
        });
        const sortedStatuses = statuses.sort((a, b) =>
          // @ts-ignore
          a.label!.localeCompare(b.label!, "en", {
            sensitivity: "base",
          })
        );
  
        return sortedStatuses;
      }
    }, [dueDateConfig]);
    const column:GridColDef[] = useMemo(()=>[
        {
            headerName:"ID",
            field:"id",
            flex:0.5
        },
        {
            headerName:"Status",
            field:"status",
            flex:0.5,
            type: "singleSelect",
            filterable:true,
            valueOptions: statusOptions,
            valueFormatter: (params: GridValueFormatterParams) => {
              try {
                if (dueDateConfig) {
                  return dueDateConfig.status[
                    Number(params.value as number)
                  ].toUpperCase();
                }
              } catch {
                return params.value;
              }
            },
            valueGetter: (params) => params.row?.status,
        },
        {
            headerName:"Last Done",
            field:"last_done",
            flex:0.5,
            filterable:false
        },
        {
            headerName:"Code",
            field:"title",
            flex:0.5,
            filterable:false
        },
        {
            headerName:"Section",
            field:"section",
            flex:0.5,
            filterable:false,
            valueGetter:(params)=> params.row?.section?.name
        },
        {
            headerName:"Type",
            field:"type_id",
            flex:0.5,
            filterable:false,
            valueGetter:(params)=> params.row?.type_obj?.name
        },
        {
            headerName:"Comment",
            field:"comment",
            flex:0.5,
            filterable:false
        },
        {
            headerName:"User",
            field:"user",
            flex:0.5,
            filterable:false,
            valueGetter:(params)=> params.row?.user_obj?.gecos
        },
        {
            headerName:"Due Date",
            field:"due_date",
            flex:0.5,
            filterable:false,
            valueGetter:(params)=> params.row,
            valueFormatter:(params:GridValueFormatterParams) =>{
              if (params.value?.last_done && params.value?.period && params.value?.last_done !== "0000-00-00"){
                const duedate = addDays(params.value?.last_done,params?.value?.period)
                return duedate
              }
              return ""
            }
        },
        {
            headerName:"Remaining",
            field:"remaining",
            flex:0.5,
            filterable:false,
            valueGetter:(params)=> params.row,
            valueFormatter:(params:GridValueFormatterParams) =>{
              if (params.value?.last_done && params.value?.period && params.value?.last_done !== "0000-00-00"){
                const duedate = addDays(params.value?.last_done,params?.value?.period)
                const remaining = daysRemaining(duedate)
                return remaining
              }
              return ""
            }
        },

        {
          headerName: "Print",
          field: "pdf",
          sortable: false,
          filterable:false,
          align: "left",
          flex: 0.3,
          renderCell: (params) => {
            return (
              <Button
                className="pdf-icon-button"
                onClick={() => {
                  try {
                    if (dueDateConfig) {
                      setPreviewSelectedStatus(
                        dueDateConfig.status_list[
                          Number(params.row?.status as number)
                        ].toUpperCase()
                      );
                    }
                  } catch {
                    setPreviewSelectedStatus(params.row?.status);
                  }
                  const rowIdAsNumber = Number(params.row.id);
                  setPreviewSelected(rowIdAsNumber);
                }}
              >
                <PictureAsPdfIcon />
              </Button>
            );
          },
        },
    ],[dueDateConfig,statusOptions])

    const getData = useCallback(
        async (params: any, axiosConfig?: CacheRequestConfig) => {
                
            // return {
            //     total:0,
            //     rows:[],
            //   }
          try {
            const resp: AxiosResponse =
              await axios.get("/v6/duedate", {
                params: params,
                paramsSerializer: (params) => queryString.stringify(params),
                ...axiosConfig,
              });
              return {
                total: resp.data.total,
                rows: resp.data.data,
              } as QueryResults<DueDateList>;
          } catch (e) {
            // @ts-ignore
            if (!axios.isCancel(e)) {
              enqueueSnackbar("Error fetching quotes", {
                variant: "error",
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center",
                },
              });
            }
            throw e;
          }
        },
        [enqueueSnackbar])

      const deleteDuedate = useCallback(() => {
        const dueDateIds = selectedRows.map((row) => row.id);
        if (!dueDateIds || dueDateIds.length === 0) {
          return;
        }
        for (const id of dueDateIds) {
          axios
            .delete(`/v6/duedate/${id}`)
            .then(() => {
              enqueueSnackbar(`Duedate deleted ${id}`, {
                variant: "success",
              });
              if (deletePayload?.apiRef) {
                deletePayload.apiRef.current.updateRows([
                  { id: id, _action: "delete" },
                ]);
              }
            })
            .catch((error) => {
              console.error(error);
              enqueueSnackbar(`Error deleting Inspection`, {
                variant: "error",
              });
            });
        }
        deletePayload?.setSelectionModel([]);
        setDeletePayload(null);
        setSelectedRows([]);
      }, [deletePayload, enqueueSnackbar, selectedRows]);


    const actions:ActionMenuItem[] = useMemo(()=>{
      const defaultActions : ActionMenuItem[] = [
        {
          name: "With Selected",
          namePlural: "With Selected",
          action: ["With_Selected", "WITH_SELECTED"],
          requiresSelection: true,
        },
        {
          name: "Duplicate",
          namePlural: "Duplicate",
          action: ["Duplicate", "DUPLICATE"],
          requiresSelection: true,
        },
        {
          name: "Copy To User",
          namePlural: "Copy To User",
          action: ["Copy_To_User", "COPY_TO_USER"],
          requiresSelection: true,
        },
        {
          name: "Set Status To Open",
          namePlural: "Set Status To Open",
          action: ["Set_Status_To_Open", "SET_STATUS_TO_OPEN"],
          requiresSelection: true,
        },
        {
          name: "Set Status To Close",
          namePlural: "Set Status To Close",
          action: ["Set_Status_To_Close", "SET_STATUS_TO_CLOSE"],
          requiresSelection: true,
        },
        {
          name: "Delete",
          namePlural: "Delete",
          action: ["Delete", "DELETE"],
          requiresSelection: true,
          multipleSelection: true,
          onClick: async (event: DueDateDeletePayload) => {
            const rows = [...event.selectedRows].map((row) => row);
  
            setTabDialogOpen(true);
            setDeletePayload(event);
            setSelectedRows(rows);
          },
        },
      ]
      return defaultActions
    },[])

    const onRowClick = useCallback(
      (param: any, event: any) => {
        let currentElement = event.target;

        while (currentElement) {
          if (
            typeof currentElement.className === "string" &&
            currentElement.className.includes("pdf-icon-button")
          ) {
            return;
          }
          currentElement = currentElement.parentElement;
        }
     
        // If the clicked element is not part of the PDF icon, navigate
        navigate(String(param.row.id));
      },
      [navigate]
    );


const advancedSearchDefaultItems = useMemo(
  () => [
    {
      id: 1,
      field: "id",
      operator: "is",
      value: "",
    },
    {
      id: 2,
      field: "status",
      operator: "is",
      value: "",
    }
    
  ],
  []
);

const onSearch = useCallback(
  (filterModel: GridFilterModel, apiRef: MutableRefObject<GridApi>) => {
    console.log("FilterModel....", filterModel);
    apiRef.current.setFilterModel(filterModel, "upsertFilterItems");
  },
  []
);


const quickSearchItems = useMemo(
  () => [
    {
      field: "id",
      operator: "startsWith",
      label: "ID",
    }
  ],
  []
);

const handleClose = useCallback(() => {
  setPreviewSelected(undefined);
}, []);

const initialSort: GridSortItem[] = useMemo(
  () => [{ field: "id", sort: "desc" }],
  []
);


  return (
   <>
   <AeronetDataGrid
   advancedSearchForm={DueDateAdvancedSearchForm}
    advancedSearchProps={{
      items: advancedSearchDefaultItems,
      onSearch: onSearch,
    }}
    components={{
      toolbar:DueDateToolbar
    }}
    initialSort={initialSort}
    disableColumnFilter = {false}
    enableFilter = {true}
    columns={column}
    data={getData}
    name='DueDate-DataGrid'
    enableCreate={canCreateDuedate}
    currentUserId={currentUser?.id}
    actions={actions}
    onRowClick = {onRowClick}
    quickSearchItems={quickSearchItems}
   />
   {previewSelected ? (
        <DueDatePreview
        duedateId={previewSelected}
          status={previewSelectedStatus}
          onClose={handleClose}
        />
      ) : null}
      <AlertDialog
        title="Delete this record?"
        open={tabDialogOpen}
        handleClose={() => {
          setTabDialogOpen(false);
        }}
      >
        <Button
          onClick={() => {
            setTabDialogOpen(false);
          }}
          color="primary"
        >
          No
        </Button>
        <Button
          onClick={() => {
            setTabDialogOpen(false);
            deleteDuedate();
          }}
          color="primary"
          autoFocus
        >
          Yes
        </Button>
      </AlertDialog>
   </>
  )
}

export default DueDateDataGrid