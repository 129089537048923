import { useEffect, useState } from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Button,
  ListItemText,
  Menu,
  MenuItem,
  MenuProps,
  Typography,
} from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import { NavLink, Outlet, useLocation } from "react-router-dom";

import { BreadcrumbHeaderListing, Title } from "../components/Header";
import { LinkRouter } from "../components/Links";


import type { AeronetViewProps } from "../types";

const breadcrumbMenu: { [key: string]: string } = {
  "/components/parts": "Parts",
  "/components/flying": "Flying",
  "/components/removed": "Removed",
  "/components/rotable": "Rotable",
  "/components/retired": "Retired",

};

const allRoutes: { [key: string]: string } = {
  ...{ "/components": "Components" },
  ...breadcrumbMenu,
 
};

const StyledNavLink = styled(NavLink)(({ theme }) => ({
  textDecoration: "none",
  fontWeight: 400,
  color: theme.palette.text.primary,
}));

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    marginTop: theme.spacing(1),
    minWidth: 150,
    backgroundColor: theme.palette.grey[200],
    "& .MuiMenuItem-root": {
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

interface InventoryLayoutProps extends AeronetViewProps {
  originalUrl: string;

}

const ComponentLayout: React.FC<InventoryLayoutProps> = (props) => {

 
  const location = useLocation();
  const popupState = usePopupState({
    variant: "popover",
    popupId: "breadcrumbMenu",
  });

  const [pathLinks, setPathLinks] = useState<string[]>([]);


  useEffect(() => {
    const _pathLinks: string[] = [];
    const paths = location.pathname.split("/");
    let pathLinkCount = 0;
    for (let i = 0; i < paths.length; i++) {
      let path = paths[i];
      console.log("path", path);

      let previousPath = _pathLinks[pathLinkCount - 1] ?? "";
      console.log("previousPath", previousPath);
      if (previousPath) {
        path = previousPath + "/" + path;
      } else {
        if (path) {
          path = "/" + path;
        }
      }
      if (path) {
        _pathLinks.push(path);
        pathLinkCount++;
      }
    }
    console.log("pathLinks: ", _pathLinks);
    setPathLinks(_pathLinks);
  }, [location.pathname]);

//   useEffect(() => {
//     setShowExtraMenu(
//       (canCreatePart &&
//         currentUser?.support_user &&
//         location.pathname.startsWith("/components/parts")) 
//     );
//   }, [canCreatePart, currentUser, location.pathname]);

  return (
    <>
      <BreadcrumbHeaderListing aria-label="breadcrumb">
        <Title>
          {pathLinks.map((path, index) => {
            if (path === location.pathname) {
              return (
                <Button
                  endIcon={<KeyboardArrowDownIcon />}
                  sx={{ textTransform: "none", pl: 0 }}
                  key={path}
                  {...bindTrigger(popupState)}
                >
                  <Typography variant="h2">
                    {allRoutes[location.pathname]}
                  </Typography>
                </Button>
              );
            } else {
              return (
                <LinkRouter to={path} key={path}>
                  {allRoutes[path]} /
                </LinkRouter>
              );
            }
          })}
        </Title>
        <StyledMenu {...bindMenu(popupState)}>
          <MenuItem key="view">
            <Typography
              sx={{ mt: 0.5, fontWeight: 500 }}
              color="text.primary"
              display="block"
            >
              View
            </Typography>
          </MenuItem>
          {Object.entries(breadcrumbMenu).map(([key, value]) => (
            <MenuItem
              onClick={popupState.close}
              key={key.split("/")[2]}
              selected={location.pathname === key}
              sx={{ opacity: 1 }}
            >
              <ListItemText>
                <StyledNavLink
                  to={key}
                  end={key === "/components" ? true : false}
                >
                  <Typography
                    color={location.pathname === key ? "primary" : "inherit"}
                    variant="inherit"
                  >
                    {value}
                  </Typography>
                </StyledNavLink>
              </ListItemText>
            </MenuItem>
          ))}
         
        </StyledMenu>
      </BreadcrumbHeaderListing>
      <Outlet />
    </>
  );
};

export default ComponentLayout;
