import { useEffect, useMemo, useState } from "react";

import SettingsIcon from "@mui/icons-material/Settings";
import {
  Grid,
  TextField as MUITextField,
  Skeleton,
  Typography,
} from "@mui/material";
import MuiAutocomplete from "@mui/material/Autocomplete";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { AxiosResponse } from "axios";
import {
  AutocompleteProps,
  AutocompleteRenderInputParams,
  fieldToAutocomplete,
  fieldToTextField,
} from "formik-mui";
import throttle from "lodash/throttle";

import { axios } from "../api";

import type { AeronetV6ItemsResponse } from "../types";
import type { IPartList } from "./types";
import { useExchangeRates } from "../hooks/useExchangeRates";

export function PartAutocomplete<
  // eslint-disable-next-line
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>(props: AutocompleteProps<IPartList, Multiple, DisableClearable, FreeSolo>) {
  const { form } = props;
  const { setFieldValue } = form;
  const [parts, setParts] = useState<Array<any>>([]);
  const [inputValue, setInputValue] = useState("");
  const { name } = fieldToTextField(props as any);
  const exchangeRates = useExchangeRates();

  const fetchParts = useMemo(
    () =>
      throttle((value: string, callback: Function) => {
        axios
          .get("/v6/parts", {
            params: {
              q: value,
              filter: JSON.stringify({
                field: "active",
                op: "==",
                value: true,
              }),
            },
          })
          .then((resp: AxiosResponse<AeronetV6ItemsResponse<IPartList>>) => {
            callback(resp.data.items);
          })
          .catch((_error) => {
            callback([]);
          });
      }, 200),
    []
  );

  useEffect(() => {
    let active = true;

    fetchParts(inputValue, (results: ReadonlyArray<IPartList>) => {
      if (active) {
        let newParts: Array<string | IPartList> = [];

        if (results) {
          newParts = [...newParts, ...results];
        }

        setParts(newParts);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, fetchParts]);

  return (
    <>
      {exchangeRates ? (
        <MuiAutocomplete
          {...fieldToAutocomplete(props)}
          fullWidth
          options={parts}
          autoComplete
          includeInputInList
          //@ts-ignore
          onChange={(_event, newValue: IPartList) => {
            const part = newValue;
            setFieldValue("part", part);
            if (typeof part === "string") {
              setFieldValue("part_id", part);
              setFieldValue("lines.0.part_id", part);
            } else {
              setFieldValue("part_id", part && part.id ? part.id : "");
              setFieldValue("lines.0.part_id", part && part.id ? part.id : "");
              setFieldValue("lines.0.lead_time_text", part && part.lead_time_text ? part.lead_time_text : "None");
              setFieldValue("uom", part?.uom || "");
            }
            if (part && typeof part !== "string") {
              setFieldValue("buy", {
                name: `${Number(part?.list_cost).toFixed(4)} ${part?.list_cost_currency ?? ""}`,
                value: part?.list_cost?.toString() || "",
              });
              setFieldValue("sell", {
                name: `${Number(part?.list_price).toFixed(4)} ${part?.list_price_currency ?? ""}`,
                value: part?.list_price?.toString() || "",
              });
              if (part.list_cost_currency) {
                setFieldValue("currency_code", part.list_cost_currency);
                setFieldValue(
                  "exchange",
                  exchangeRates[part.list_cost_currency]
                );
              }
            }
          }}
          onInputChange={(_event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          getOptionLabel={(option) => {
            return typeof option === "string" ? option : (option.number ?? '')
          }
          }
          isOptionEqualToValue={(option, value) =>
            option.number === value.number
          }
          renderInput={(params: AutocompleteRenderInputParams) => (
            <MUITextField
              {...params}
              label="Part #"
              error={props.form.touched[name!] && !!props.form.errors[name!]}
              // @ts-ignore
              helperText={props.form.touched[name!] && props.form.errors[name!]}
              fullWidth
            />
          )}
          renderOption={(props, option, state) => {
            const matches = match(option.number, state.inputValue);
            const parts = parse(option.number, matches);

            return (
              <li {...props}>
                <Grid container alignItems="center">
                  <Grid item>
                    <SettingsIcon
                      sx={{
                        color: "text.secondary",
                        marginRight: 2,
                      }}
                    />
                  </Grid>
                  <Grid item xs>
                    {parts.map((part, index) => (
                      <span
                        key={index}
                        style={{ fontWeight: part.highlight ? 700 : 400 }}
                      >
                        {part.text}
                      </span>
                    ))}
                    <Typography variant="body2" color="textSecondary">
                      {option.description} ({option.id})
                    </Typography>
                  </Grid>
                </Grid>
              </li>
            );
          }}
        />
      ) : (
        <Skeleton animation="wave" sx={{ fontSize: "2rem" }} />
      )}
    </>
  );
}

PartAutocomplete.displayName = "FormikMaterialUIPartAutocomplete";
