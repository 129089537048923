import { useCallback, useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PrintIcon from "@mui/icons-material/Print";
import {
  Box,
  CircularProgress,
  Dialog,
  IconButton,
  Skeleton,
  Slide,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import type { TransitionProps } from "@mui/material/transitions";
import { useSnackbar } from "notistack";
import queryString from "query-string";
import React from "react";

import { axios } from "../api";
import { useGlobalConfig } from "../hooks/useGlobalConfig";
import { DueDateDetailView } from "./type";
export const getStationeryURL = () => {
  if (process.env.REACT_APP_ENV === "prod") {
    return "https://api.module.nz/stationery/";
  } else if (process.env.REACT_APP_ENV === "local") {
    return "https://dev.api.module.nz/stationery/";
  }
  return "https://dev.api.module.nz/stationery/";
};
const stationeryUrl = getStationeryURL();

interface SorPreviewProps {
  duedateId: number;
  onClose: () => void;
  status: any;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});



const DueDatePreview: React.FC<SorPreviewProps> = ({ duedateId, status, onClose }) => {
  const [src, setSrc] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const theme = useTheme();
  const [page, setPage] = useState<string>("");
  const [pdfLoading, setPdfLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const globalConfig = useGlobalConfig();
  const [open, setOpen] = useState(true);

  const handleClose = useCallback(() => {
    setSrc("");
    setTitle("");
    setOpen(false);
    onClose();
  }, [onClose]);

  useEffect(() => {
    let active = true;

    async function getPage() {
      try {
        const resp = await axios.get<DueDateDetailView>(`/v6/duedate/${duedateId}`, {
          id: `get-duedate-${duedateId}`,
        });
        const duedate = resp.data;
        duedate['created_by_name'] = resp.data.user_obj?.gecos || ""
        duedate['section_name'] = resp.data.section?.name || ""
        duedate['status'] = status
        duedate['type_id'] = resp.data.type_obj?.name || ""
        duedate['company_id'] = resp.data.company_obj?.name || ""
        duedate['report_date'] = resp.data.last_done
        const context = JSON.stringify(duedate);

        const previewUrl =
          stationeryUrl +
          "?" +
          queryString.stringify({
            "template-name": "duedate/due_date.html",
            context,
          });

        const page = await axios.get<string>(previewUrl, {
          responseType: "text",
        });
        if (active) {
          setTitle(`"DueDate: ${duedate.id}`);
          setSrc(previewUrl);
          setPage(page.data);
        }
      } catch (error) {
        enqueueSnackbar("Error loading preview", {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        });
        handleClose();
      }
    }

    if (duedateId && globalConfig) {
      getPage();
    }

    return () => {
      active = false;
    };
  }, [duedateId, handleClose, enqueueSnackbar, globalConfig, status]);

  const handlePrint = useCallback(() => {
    const id = duedateId.toString();

    // @ts-ignore
    const iframe = document.frames
      ? // @ts-ignore
        document.frames[id]
      : document.getElementById(id);
    const iframeWindow = iframe.contentWindow || iframe;

    iframe.focus();
    iframeWindow.print();

    return false;
  }, [duedateId]);

  const handlePDF = useCallback(async () => {
    setPdfLoading(true);
    const pdf = await axios.get<BlobPart>(src + "&format=pdf", {
      responseType: "blob",
      headers: { Accept: "application/pdf" },
    });
    const blob = new Blob([pdf.data], { type: "application/pdf" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `sor-${duedateId}.pdf`;
    setPdfLoading(false);
    link.click();
  }, [src, duedateId]);

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth={true}
      maxWidth="md"
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      TransitionComponent={Transition}
      PaperProps={{
        sx: {
          height: "100%",
        },
      }}
    >
      <Box
        component="header"
        pl={{ xs: 2, sm: 6 }}
        pr={{ xs: 2, sm: 6 }}
        sx={{
          backgroundColor: grey[200],
          minHeight: "52px",
          alignItems: "center",
          placeContent: "stretch",
          justifyContent: "center",
        }}
        display="flex"
        flexShrink={0}
      >
        <Box component="span">
          <Typography variant="h4" component="h1" textAlign="center">
            DueDate Preview
          </Typography>
          <Box
            display="flex"
            sx={{
              position: "absolute",
              right: 0,
              top: 0,
              flexFlow: "row wrap",
              pt: 1,
            }}
          >
            <Tooltip title="PDF">
              <IconButton
                edge="end"
                color="inherit"
                onClick={handlePDF}
                aria-label="close"
                sx={{
                  alignSelf: "flex-start",
                  color: { sm: "secondary" },
                  m: 1,
                  p: 0,
                }}
                disabled={pdfLoading}
              >
                <PictureAsPdfIcon />
                {pdfLoading && (
                  <CircularProgress
                    size={24}
                    style={{ position: "absolute" }}
                  />
                )}
              </IconButton>
            </Tooltip>
            <Tooltip title="Print">
              <IconButton
                edge="end"
                color="inherit"
                onClick={handlePrint}
                aria-label="close"
                sx={{
                  alignSelf: "flex-start",
                  color: { sm: "secondary" },
                  m: 1,
                  p: 0,
                }}
              >
                <PrintIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Close">
              <IconButton
                edge="end"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
                sx={{
                  alignSelf: "flex-start",
                  color: { sm: "secondary" },
                  m: 1,
                  p: 0,
                  "&:hover": { backgroundColor: { sm: grey[400] } },
                }}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Box>
      {page ? (
        <iframe
          key={duedateId}
          srcDoc={page}
          id={duedateId?.toString()}
          title={title}
          style={{
            flexGrow: 1,
            border: 0,
            margin: 0,
            padding: 0,
            height: "100%",
            width: "100%",
          }}
        ></iframe>
      ) : (
        <Box m={3}>
          <Box width={300}>
            <Skeleton animation="wave" sx={{ fontSize: "4rem" }} />
          </Box>
          <Skeleton
            variant="rectangular"
            width={210}
            height={140}
            sx={{ marginBottom: 1 }}
          />
          {Array(4)
            .fill(1)
            .map((card, index) => (
              <Skeleton
                animation="wave"
                sx={{ fontSize: "2rem" }}
                key={index}
              />
            ))}
        </Box>
      )}
    </Dialog>
  );
};

export default DueDatePreview;
