import { useMemo } from "react";

import { TextField as MUITextField } from "@mui/material";
import MuiAutocomplete, {
  createFilterOptions,
} from "@mui/material/Autocomplete";
import {
  AutocompleteProps,
  fieldToAutocomplete,
  fieldToTextField,
} from "formik-mui";

import type { PriceOption } from "./types";
import { useGlobalConfig } from "../hooks/useGlobalConfig";

const filter = createFilterOptions({
  matchFrom: "start",
  stringify: (option: any) => option.name,
});

export interface PriceAutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
  prices: PriceOption[];
  label: string;
  required?: boolean;
}

export function ItemPriceAutocomplete<
  // eslint-disable-next-line
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>(
  props: PriceAutocompleteProps<
    PriceOption,
    Multiple,
    DisableClearable,
    FreeSolo
  >
) {
  const { prices, label, required=false, ...autocompleteProps } = props;
  const { setFieldValue } = autocompleteProps.form;
  const { name } = fieldToTextField(props as any);
  const fieldLabel = useMemo(() => {
    let l = label + " (Per UOM)";
    if (required) {
      return l + "*";
    }
    return l;
  }, [label, required]);
  const globalConfig = useGlobalConfig();

  const customCurrency = useMemo(() => {
    if (prices.length > 0) {
      // splits on whitespace and returns the last element
      return prices[0]["name"].split(" ").slice(-1)[0];
    }
  }, [prices]);

  return (
    <MuiAutocomplete
      {...fieldToAutocomplete(autocompleteProps)}
      fullWidth
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={prices}
      onChange={(event, newValue) => {
        if (typeof newValue === "string") {
          if (name) {
            setFieldValue(name, {
              name: newValue,
            });
          }
          // @ts-ignore
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          if (name) {
            // @ts-ignore
            const customPrice = Number(newValue.inputValue).toFixed(globalConfig?.value_rounding ?? 2)
            setFieldValue(name, {
              // @ts-ignore
              name: `Custom ${customPrice} ${customCurrency}`,
              // @ts-ignore
              value: customPrice,
            });
          }
        } else {
          if (name) {
            setFieldValue(name, newValue);
          }
        }
      }}
      // @ts-ignore
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        // Suggest the creation of a new value
        if (params.inputValue !== "") {
          const customPrice = Number(params.inputValue).toFixed(globalConfig?.value_rounding ?? 2)
          filtered.push({
            inputValue: customPrice,
            name: `Use Custom value "${customPrice}"`,
          });
        }

        return filtered;
      }}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return `Custom ${option.inputValue}`;
        }
        // Regular option
        return option.name;
      }}
      renderOption={(props, option) => <li {...props}>{option.name}</li>}
      renderInput={(params) => (
        <MUITextField
          {...params}
          label={fieldLabel}
          error={props.form.touched[name!] && !!props.form.errors[name!]}
          // @ts-ignore
          helperText={props.form.touched[name!] && props.form.errors[name!]}
        />
      )}
    />
  );
}

ItemPriceAutocomplete.displayName = "FormikMaterialUIItemPriceAutocomplete";
