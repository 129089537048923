import { useEffect, useMemo, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import BuildIcon from "@mui/icons-material/Build";
import { Grid, TextField as MUITextField } from "@mui/material";
import MuiAutocomplete from "@mui/material/Autocomplete";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { AxiosResponse } from "axios";
import {
  AutocompleteProps,
  AutocompleteRenderInputParams,
  fieldToAutocomplete,
  fieldToTextField,
} from "formik-mui";
import throttle from "lodash/throttle";
import queryString from "query-string";

import { axios } from "../api";

import type { AeronetV6ItemsResponse } from "../types";
import type { IJob } from "./types";
import { FormikAddJob } from "./FormikAddJob";
import { useConfig } from "../hooks/useConfig";
import { Quote } from "../quotes/types";
import { IAircraftSelect } from "../aircraft/types";

export interface JobAutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
  label?: string;
  status: number[];
  quote?: Quote & { deliver: {id: string, name: string} };
  aircraft?: IAircraftSelect[];
  isAutoCreateJob?: boolean;
  isCreateJob?: boolean;
  handleAddDemand?: () => void;
  onChange?: (e,value) => void;
}

export function JobAutocomplete<
  // eslint-disable-next-line
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>(props: JobAutocompleteProps<IJob, Multiple, DisableClearable, FreeSolo>) {
  // @ts-ignore
  const { form, label = "Work Order Number", status, quote, isAutoCreateJob, isCreateJob, handleAddDemand,onChange } = props;
  const { setFieldValue } = form;
  const [jobs, setJobs] = useState<IJob[]>([]);
  const [inputValue, setInputValue] = useState("");
  const { name } = fieldToTextField(props as any);
  const addLabel = "Add new job";
  const jobConfig = useConfig("jobs");

  const fetchJobs = useMemo(
    () =>
      throttle((value: string, callback: Function) => {
        let params: { [key: string]: any } = {
          page: 1,
          size: 20,
          sort: "-id",
          filter: [],
        };
        if (status && status.length > 0) {
          params["filter"].push(
            JSON.stringify({ field: "status", op: "in", value: status })
          );
        }
        if (value && value !== addLabel) {
          params["filter"].push(
            JSON.stringify({ field: "id", op: "ilike", value: value })
          );
          params["sort"] = "id";
        }
        axios
          .get("/v6/jobs", {
            params: params,
            paramsSerializer: (params) => queryString.stringify(params),
          })
          .then((resp: AxiosResponse<AeronetV6ItemsResponse<IJob>>) => {
            callback(resp.data.items);
          })
          .catch((error) => {
            callback([]);
          });
      }, 200),
    [status]
  );

  useEffect(() => {
    let active = true;

    fetchJobs(inputValue, (results: ReadonlyArray<IJob>) => {
      if (active) {
        let newJobs: IJob[] = [];

        if (results) {
          newJobs = [...newJobs, ...results];
        }

        // @ts-ignore
        newJobs.unshift(addLabel);

        setJobs(newJobs);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, fetchJobs, addLabel]);

  return (
    <>
      <MuiAutocomplete
        {...fieldToAutocomplete(props)}
        fullWidth
        options={jobs}
        autoComplete
        includeInputInList
        onChange={(_event, newValue) => {
          setFieldValue("job", newValue);
          if (typeof newValue === "string") {
            setFieldValue("job_id", newValue);
          } else {
            // @ts-ignore
            setFieldValue("job_id", newValue && newValue.id ? newValue.id : "");
          }
          onChange && onChange(_event, newValue)
        }}
        onInputChange={(_event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        getOptionLabel={(option) => {
          if (typeof option === "string") {
            return option;
          } else {
            if (option.name) {
              return `${option.id!} - ${option.name}`;
            } else if (option.id) {
              return option.id!.toString();
            }
          }
          return "";
        }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <MUITextField
            {...params}
            error={form.touched[name!] && !!form.errors[name!]}
            // @ts-ignore
            helperText={form.touched[name!] && form.errors[name!]}
            label={label}
          />
        )}
        renderOption={(props, option, state) => {
          if (typeof option === "string") {
            return (
              <li {...props} key={-1}>
                <Grid container alignItems="center">
                  <Grid item>
                    <AddIcon
                      sx={{
                        color: "text.secondary",
                        marginRight: 2,
                      }}
                    />
                  </Grid>
                  <Grid item xs>
                    <span
                      style={{
                        fontWeight: 700,
                      }}
                    >
                      {option}
                    </span>
                  </Grid>
                </Grid>
              </li>
            );
          } else {
            const matches = match(option.id!, state.inputValue);
            const jobs = parse(option.id!, matches);

            return (
              <li {...props}>
                <Grid container alignItems="center">
                  <Grid item>
                    <BuildIcon
                      sx={{
                        color: "secondary",
                        marginRight: 2,
                      }}
                    />
                  </Grid>
                  <Grid item xs>
                    {jobs.map((job, index) => (
                      <span
                        key={index}
                        style={{
                          fontWeight: job.highlight ? 700 : 400,
                        }}
                      >
                        {job.text}
                      </span>
                    ))}
                    {option.name ? " - " + option.name : null}
                  </Grid>
                </Grid>
              </li>
            );
          }
        }}
      />
      {
        typeof props.form.values[name!] === "string" &&
        props.form.values[name!].startsWith("Add") && (
          <FormikAddJob
            setValue={(newJob) => {
              setFieldValue(name!, newJob);
              setFieldValue('job_id', (newJob as IJob).id ?? '');
              setTimeout(() => {
                handleAddDemand?.(); 
              });
            }}
            jobConfig={jobConfig}
            quote={quote}
            isAutoCreateJob={isAutoCreateJob}
            isCreateJob={isCreateJob}
          />
        )}
    </>
  );
}

JobAutocomplete.displayName = "FormikMaterialUIJobAutocomplete";
