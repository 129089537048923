import { BasePart } from "../parts/types";
import { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro";

export interface IDivision {
  id: number;
  name: string;
  custom_field: string | null;
}

export enum OrderType {
  None = "",
  Customer = "customer",
  Supplier = "supplier",
}

export interface ICompany {
  id: number;
  name: string;
}

export interface IUser {
  id: number;
  name: string;
}

export interface IAddress {
  line1?: string;
  line2?: string;
  city?: string;
  state?: string;
  post_code?: string;
  country?: string;
}

export interface IOrderList {
  id: number;
  active: boolean | null;
  address_line_1: string | null;
  address_line_2: string | null;
  address_line_3: string | null;
  address_line_4: string | null;
  created_by_user: IUser | null;
  created_by: number | null;
  created: Date | string | null;
  currency_code: string | null;
  date_issued: Date | string | null;
  date_required: string | null;
  description: string | null;
  division: IDivision | null;
  gst_total: number | null;
  job_id: number | null;
  locked_by: number | null;
  modified_by_user: IUser | null;
  modified_by: number | null;
  modified: Date | string | null;
  read_only: Date | string | null;
  status_id: number | null;
  supplier_address_1: string | null;
  supplier_address_2: string | null;
  supplier_address_3: string | null;
  supplier_address_4: string | null;
  supplier_id: number | null;
  supplier_name: string | null;
  total: number | null;
  user_id: number | null;
  lines?: IOrderLine[];
}

export interface IOrderLine {
  description: string;
  discount: string;
  line_condition: string;
  number: string;
  priority: string;
  quantity: string;
  sub_total: string;
  unit: string;
  buy: string;
  created_by: number;
  created: string;
  detail_type_id: number;
  id: number;
  order_detail: IOrderLineDetail;
  sell: string;
  serial_number: string | null;
  part: IOrderBasePart;
  order: IOrderBase;
}

export interface IOrderBasePart extends BasePart {
  id: number;
  number: string;
  description: string;
}

export interface IOrderDetail {
  deliver_address?: {
    line1?:string,
    line2?:string,
    city?:string,
    state:string,
    post_code?:string,
    country?:string
  };
  id?: number;
  active: boolean;
  deliver: ICompany | null;
  address_line_1: string;
  address_line_2: string;
  address_line_3: string;
  address_line_4: string;
  deliver_address_line1?: string;
  deliver_address_line2?: string;
  deliver_address_line3?: string;
  deliver_address_line4?: string;
  aircraft: string;
  status: any;
  created_by_user: IUser;
  created_by: number;
  created: string;
  authorized_by_user: IUser;
  authorized_by: number;
  currency_code: string;
  date_required: string | null;
  description: string | null;
  division: IDivision;
  gst_total: string;
  job_id: number | null;
  locked_by: number | null;
  modified_by_user: IUser;
  modified_by: number;
  modified: string;
  read_only?: boolean | null;
  reference: string;
  ship_via: number;
  stationery: string;
  status_id: number;
  sub_total: string;
  account_status: number;
  supplier_address: any;
  supplier_address_1: string;
  supplier_address_2: string;
  supplier_address_3: string;
  supplier_address_4: string;
  supplier_id: number;
  supplier_name: string;
  total: string;
  user_id: number;
  lines?: IOrderLine[];
  company: ICompany | null
}

export interface IOrderLineDetail {
  id: number;
  description: string | null;
  status_id: number | null;
}

export interface IOrderPart extends IOrderLine {
  total: number;
  checked?: boolean;
  jobID: number | null;
}

export interface IOrderListOptional extends Partial<IOrderList> {}

export interface IOrderDeletePayload {
  selectedRows: any;
  setSelectionModel: (arg0: any) => void;
  apiRef: React.MutableRefObject<GridApiPro>;
}

export interface IOrderBase {
  id: number;
  order_num: string;
  description: string;
  gst_rate: number;
  currency_code: string;
}
